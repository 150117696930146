import { initializeHtmlElement } from '../HTMLElementExtensions';
import allPaidPaymentStatus from '../../HtmlElements/Enumerations/AllPaidPaymentStatus'

class AllPaidTransactionsGrid extends HTMLElement {

    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="allpaid-transactions-report"> </bootstrap-retport-grid>',
            [],
            []
        );
        this._tableColumns = [];
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
        this._pageTools = new PageTools();

        this._bootstrapReportGrid.dateFormatter = this._bootstrapReportGrid.dateFormatter.bind(this);
        this._allPaidTransactionsDollarFieldFormatter = this._allPaidTransactionsDollarFieldFormatter.bind(this);
        this._statusFormatter = this._statusFormatter.bind(this);
        this._paymentPortalCustomFeeFieldFormatter = this._paymentPortalCustomFeeFieldFormatter.bind(this);
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        this.shadowRoot.querySelector("bootstrap-report-grid").shadowRoot.querySelector('bootstrap-table').shadowRoot.querySelector('#export-pdf').toggleAttribute('hidden', true);
    }
    set model(value) {
        this._model = value;
        this._bootstrapReportGrid.initBootstrapReportGrid(this._createTableConfig());
    }

    _compileColumns() {
        this._columns = [
            {
                title: 'Timestamp',
                field: 'TimeStamp',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter
            },
            {
                title: 'Reference Number',
                field: 'ReferenceNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Card Type',
                field: 'CardType',
                type: 'Text',
                escape: true
            },
            {
                title: 'Card Holder Name',
                field: 'CardHolderName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Payor Name',
                field: 'PayorName',
                type: 'Text',
                escape: true
            },
            {
                title: 'SO Number',
                field: 'SoNumber',
                type: 'Text',
                escape: false
            },
            {
                title: 'Booking',
                field: 'BookingNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Inmate Name',
                field: 'InmateName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Payment Status',
                field: 'Status',
                type: 'Text',
                escape: true,
                formatter: this._formatPaymentStatus
            },
            {
                title: 'Amount',
                field: 'PaymentAmount',
                type: 'Number',
                escape: true,
                formatter: this._allPaidTransactionsDollarFieldFormatter
            },
            {
                title: 'Canceled By',
                field: 'CanceledBy',
                type: 'Text',
                escape: false,
            },
            {
                title: 'Canceled Reason',
                field: 'CanceledReason',
                type: 'Text',
                escape: false,
            }
        ];

        if (this._model) {
            const distinctFeeColumnNames = [];
            for (const fee of this._model) {
                if (!distinctFeeColumnNames.includes(fee.Name)) {
                    this._columns.push({
                        title: fee.Name,
                        field: fee.Name,
                        type: 'Number',
                        escape: true,
                        formatter: this._paymentPortalCustomFeeFieldFormatter
                    });

                    distinctFeeColumnNames.push(fee.Name);
                }
            }
        }
    }

    _createTableConfig() {
        this._compileColumns();
        return {
            uniqueId: 'AllPaidTransactionsGrid',
            tableColumns: this._columns,
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: 'Public-Cash-Bond-Transactions',
            reportUrl: '/Reports/AllPaidTransactions_Read',
            columnFilters: true,
            sortable: true,
            restoreFilters: true,
            tableName: 'AllPaid Transactions',
            tableClasses: 'table-striped',
            paginationDetails: true,
            rowStyleFormatter: this._statusFormatter,
            toolbar: true,
            tableAlertMessage: true
        };
    }

    _statusFormatter(row, index) {
        const status = allPaidPaymentStatus[`${row.Status}`].string;
        if (status === allPaidPaymentStatus.cancelled.string) {
            return {
                classes: ["text-danger"]
            }
        }
        return {
            classes: []
        }
    }

    _formatPaymentStatus(value, row, index) {
        if (isNaN(value))
            return allPaidPaymentStatus[`${value}`] !== undefined ?
                allPaidPaymentStatus[`${value}`].string : value;
        else
            return value;
    }

    _allPaidTransactionsDollarFieldFormatter(value, row, index) {
        const status = allPaidPaymentStatus[`${row.Status}`].string;
        const isNegative = status === allPaidPaymentStatus.cancelled.string;
        const amount = this._pageTools.formatNumberToDollarAmount(value);

        return isNegative ? `<div>(${amount})</div>` : `<div>${amount}</div>`; 
    }

    _paymentPortalCustomFeeFieldFormatter(_, rowData, rowIndex, rowField) {
        // This might fall under YAGNI, but if we have multiple fees with the same name all on the same BondType,
        //     this code will aggregate them into one column.
        const models = rowData.CustomFees.filter(model => model.Name === rowField);
        const modelAmounts = models.map(model => model.Amount);
        const modelAmountsSum = modelAmounts.reduce((a, b) => a + b, 0);

        // This line is needed to persist the calculated data for the row column to table's row data.
        // It is also needed in order for these custom column to not appear collapsed or scrunched up in the xlsx export.
        rowData[rowField] = modelAmountsSum;

        return this._allPaidTransactionsDollarFieldFormatter(modelAmountsSum, rowData, rowIndex);
    }
}

customElements.define('allpaid-transactions-grid', AllPaidTransactionsGrid)