import { FeatureFlag } from "../types/Library/Common/Enumerations/FeatureFlag";
import offenseStatus from "./Enumerations/OffenseStatus";

export function saveDialog(options) {
    if (!options.form.checkValidity()) {
        options.form.reportValidity();
        return;
    }
    options.pageTools.toggleTriggers(options.dialogElement.shadowRoot, true);
    clearAlert(options.alertElement);

    const callback = options.customSaveCallback ??
        saveCallback.bind(
            null,
            options.dialogElement,
            options.alertElement,
            options.modalComponent,
            options.data,
            options.pageTools,
            options.useGenericErrorMessage
        );

    const xhrWrapper = new XhrWrapper();
    xhrWrapper.makeRequest(
        'POST',
        options.url,
        options.data,
        callback
    );
}

function saveCallback(dialogElement, alertElement, modalComponent, data, pageTools, useGenericErrorMessage, response, status) {
    pageTools.toggleTriggers(dialogElement.shadowRoot, false);
    if (!status) {
        showAlert(alertElement, useGenericErrorMessage ? 'Failed to save. Review the information you have entered and try again.' : response);
        return;
    }
    const event = new CustomEvent('save', { detail: data });
    dialogElement.dispatchEvent(event);
    modalComponent.closeModal();
}

export function showAlert(alertElement, message) {
    alertElement.innerHTML = message;
    alertElement.classList.remove('d-none');
    alertElement.scrollIntoView();
}

export function clearAlert(alertElement) {
    alertElement.textContent = '';
    alertElement.classList.add('d-none');
}

export function ensureListHasSameValuesAndDisplay(items, propertyAndElementArray) {
    const firstItem = items[0];
    const propertiesWithDifferences = [];
    propertyAndElementArray.forEach(pair => {
        let different = false;
        items.forEach(item => {
            // https://stackoverflow.com/a/48300450
            different = different || !Object.is(item[pair.property], firstItem[pair.property]);
        });
        if (pair.element) {
            pair.element.value = (!different ? firstItem[pair.property] : pair.default) ?? null; // Null coalesce prevents text of "undefined" showing up in fields
        }
        if (pair.callback) {
            pair.callback(firstItem, different, pair);
        }
        if (different) {
            propertiesWithDifferences.push(pair.property);
        }
    });
    return propertiesWithDifferences;
}

export function handleDropdownDifferencesCallback(item, different, options) {
    const value = item[options.property];
    if (different || !value) {
        options.element.selectedIndex = 0; // Select One
    } else {
        options.element.value = value;
    }
}

export function getSpecialConditionsInfo(specialConditions, specialConditionsFlag, verboseSpecialConditionsFlag) {
    if(specialConditionsFlag)
        return specialConditions !== null ? offenseStatus.completed.string : offenseStatus.required.string;

    if (verboseSpecialConditionsFlag)
        return specialConditions !== null ? offenseStatus.completed.string : offenseStatus.optional.string;

    return specialConditions !== null ? offenseStatus.completed.string : offenseStatus.required.string;
}

export function calculateState(value, isRequired, isDisplayed) {
    if (isRequired && !value)
        return offenseStatus.required.string;
    if (value && isDisplayed)
        return offenseStatus.completed.string;
    return offenseStatus.optional.string;
}

function aggregateState(currentState, newState) {
    if (currentState === offenseStatus.required.string || newState === offenseStatus.required.string)
        return offenseStatus.required.string;
    if (currentState === offenseStatus.completed.string || newState === offenseStatus.completed.string)
        return offenseStatus.completed.string;
    return offenseStatus.optional.string;
}
export function getArrestInfoStatus(arrestInfo, visibilityStateObject) {
    return [
        calculateState(arrestInfo.agencyCaseNumberField, arrestInfo.agencyCaseNumberRequired, visibilityStateObject.isAgencyCaseNumberDisplayed),
        calculateState(arrestInfo.extraditionStateField, arrestInfo.extraditionInfoRequired, visibilityStateObject.isExtraditionStateDisplayed),
        calculateState(arrestInfo.courtNameField, arrestInfo.courtRequired, visibilityStateObject.isCourtInfoDisplayed),
        calculateState(arrestInfo.causeWarrantField, settings.userIsCounty && settings.featureFlags[FeatureFlag.CourtInformationWarrantNumberRequired], visibilityStateObject.isWarrantInfoDisplayed),
        calculateState(arrestInfo.citationNumberField, arrestInfo.citationNumberRequired, visibilityStateObject.isCitationNumberDisplayed)
    ].reduce(aggregateState);
}

export function getAuthorizedByInfo(authorization) {
    return authorization !== null ? offenseStatus.completed.string : offenseStatus.required.string;
}

/**
 * @param {any} table
 * @param {any} event
 * @param {{required: string, optional: string, readOnly: string}} courtOptions
 * @param {any} agencyCaseNumberRequired
 * @param {any} extraditionInfoRequired
 * @param {any} visibilityState
 */
export function updateArrestInfo(table, event, courtOptions, citationNumberRequired, agencyCaseNumberRequired, extraditionInfoRequired, visibilityState) {
    const data = event.detail;
    const $table = $(table);
    data.selected.forEach(id => {
        const row = $table.bootstrapTable('getRowByUniqueId', id);

        if (!data.ignoreWarrantNumber)
            row.CourtDetails.WarrantNum = data.warrant;
        if (!settings.featureFlags[FeatureFlag.CitationNumberReadOnly]) {
            row.CourtDetails.CitationNumber = data.citationNumber;
        }
        if (!courtOptions.readOnly) {
            row.CourtDetails.Court = data.court;
            row.CourtDetails.CourtName = data.ooCountyCourtName;
            row.CourtDetails.CourtAddress = data.ooCountyCourtAddress;
            row.CourtDetails.CourtCity = data.ooCountyCourtCity;
            row.CourtDetails.CourtCounty = data.ooCountyCourtCounty;
        }
        row.CourtDetails.CauseNum = data.causeNum;
        row.CourtDetails.AgencyCaseNumber = data.agencyCaseNumber;
        row.CourtDetails.ExtraditionState = data.extraditionState;
        row.CourtDetails.CourtDate = data.courtDate;
        row.CourtDetails.CourtTime = data.courtTime;
        row.CourtDetails.AlternateCourt = data.alternateCourtDate;

        const details = row.CourtDetails;
        const arrestInfoStatusObject = {
            courtNameField: details.Court || (details.CourtName && details.CourtAddress && details.CourtCity && details.CourtCounty),
            agencyCaseNumberField: details.AgencyCaseNumber,
            causeWarrantField: details.CauseNum || details.WarrantNum,
            citationNumberField: details.CitationNumber,
            citationNumberRequired: citationNumberRequired,
            courtRequired: courtOptions.required,
            courtOptional: courtOptions.optional,
            agencyCaseNumberRequired: agencyCaseNumberRequired,
            extraditionInfoRequired: extraditionInfoRequired,
            extraditionStateField: details.ExtraditionState
        }

        row.ArrestInfo = getArrestInfoStatus(arrestInfoStatusObject, visibilityState);

        $table.bootstrapTable('updateByUniqueId', {
            id: id,
            row: row
        });
    });
}

export function updateSpecialConditionsInfo(table, rowKey, event, specialConditionsFlag, verboseSpecialConditionsFlag) {
    const data = event.detail;
    const $table = $(table);
    data.selected.forEach(id => {
        const row = $table.bootstrapTable('getRowByUniqueId', id);
        row[rowKey].SpecialConditions = data.specialConditions;
        row[rowKey].VerboseSpecialConditions = verboseSpecialConditionsFlag ? data.verboseSpecialConditions : null;

        row.SpecialConditionsInfo = getSpecialConditionsInfo(data.specialConditions, specialConditionsFlag, verboseSpecialConditionsFlag);

        $table.bootstrapTable('updateByUniqueId', {
            id: id,
            row: row
        });
    });
}

export function updateAuthorizationInfo(table, rowKey, event) {
    const data = event.detail;
    const $table = $(table);
    data.selected.forEach(id => {
        const row = $table.bootstrapTable('getRowByUniqueId', id);
        row[rowKey].ReviewAuthorizedBy = data.reviewAuthorizedBy;

        row.AuthorizationInfo = getAuthorizedByInfo(data.reviewAuthorizedBy);

        $table.bootstrapTable('updateByUniqueId', {
            id: id,
            row: row
        });
    });
}