import type {
    CellClassParams,
    CellEditorSelectorResult, ColDef, GetRowIdParams, GridApi, GridOptions, ICellEditorParams, ICellRendererParams, IDetailCellRendererParams,
    ISelectCellEditorParams, RowDataUpdatedEvent, ValueFormatterParams
} from "ag-grid-enterprise";
import type { BondEditDetailViewModel } from "../../types/Website/Bonds/Areas/Application/Models/BondEditDetailViewModel";
import type { BondEditPageViewModel } from "../../types/Website/Bonds/Areas/Application/Models/BondEditPageViewModel";
import type { BondEditViewModel } from "../../types/Website/Bonds/Areas/Application/Models/BondEditViewModel";
import type { CourtViewModel } from "../../types/Website/Bonds/Models/CourtViewModel";
import type { ActionButton } from "../ActionButton";
import { BasicGrid } from "../Grid/BasicGrid";
import { ButtonRenderer, ButtonRendererParams } from "../Grid/ButtonRenderer";
import { CurrencyCellEditor, ICurrencyCellEditorParams } from "../Grid/CurrencyCellEditor";
import { getDefaultDateStringDataDefinition } from "../Grid/DateStringDataTypeDefinition";
import { TimeStringCellEditor } from "../Grid/TimeStringCellEditor";
import { getDefaultTimeStringDataDefinition } from "../Grid/TimeStringDataTypeDefinition";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { DateTimeFormatter } from "../Shared/DateTimeFormatter";
import { nullThrow } from "../TypeScriptFunctions";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import template from "./EditBondGrid.html";
import { ErrorDetailObject } from "./ErrorDetailObject";

export class EditBondGrid extends HTMLElement {
    private readonly _defaultOption = "Select one";
    private readonly _dateTimeFormatter: DateTimeFormatter;
    private readonly _pageTools: IPageTools;
    private _courts: CourtViewModel[] = [];
    private _courtOptions: string[] = [];
    private _bondApplications: BondEditViewModel[] = [];
    public gridApi: GridApi<BondEditViewModel> | undefined;
    private _originalDetailData: BondEditDetailViewModel[] = [];
    private _isSaving: boolean = false;
    public set data(value: BondEditPageViewModel) {
        this._courts = value.Courts;
        this._courtOptions = this._courts.map(x => x.CourtName).sort((a, b) => a.localeCompare(b));
        this._courtOptions.unshift(this._defaultOption);
        this._bondApplications = value.BondApplications;
        this.gridApi?.setGridOption("rowData", this._bondApplications);
        // Deep clone the original detail data for comparison
        this._originalDetailData = JSON.parse(
            JSON.stringify(
                value.BondApplications.flatMap((bond) => bond.BondApplicationDetails)
            )
        );
    }

    constructor() {
        super();
        this._pageTools = new PageTools();
        this._dateTimeFormatter = new DateTimeFormatter();
        initializeHtmlElement(this, template, [bootstrap], []);

        this._formatDateField = this._formatDateField.bind(this);
        this._cancelEdit = this._cancelEdit.bind(this);
        this._saveEdit = this._saveEdit.bind(this);
    }

    connectedCallback() {
        const grid = <BasicGrid>nullThrow(this.shadowRoot?.getElementById("grid"));
        const columnDefs = this._createColumnDefinitions();
        const detailColumnDefs = this._createDetailColumnDefinitions();
        const options: GridOptions<BondEditViewModel> = {
            popupParent: document.querySelector("body"),
            masterDetail: true,
            detailRowAutoHeight: true,
            columnDefs: columnDefs,
            detailCellRendererParams: (params: ICellRendererParams) => <IDetailCellRendererParams<BondEditViewModel, BondEditDetailViewModel>>{
                detailGridOptions: <GridOptions<BondEditDetailViewModel>>{
                    popupParent: document.querySelector("body"),
                    context: params,
                    singleClickEdit: true,
                    columnDefs: detailColumnDefs,
                    dataTypeDefinitions: {
                        dateString: getDefaultDateStringDataDefinition<BondEditDetailViewModel>(),
                        timeString: getDefaultTimeStringDataDefinition<BondEditDetailViewModel>()
                    },
                    stopEditingWhenCellsLoseFocus: true,
                    getRowId: (params: GetRowIdParams) => String(nullThrow(params.data).BondApplicationDetailId),

                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.BondApplicationDetails);
                }
            },
            onRowDataUpdated: (params: RowDataUpdatedEvent) => {
                for (let i = 0; i < params.api.getDisplayedRowCount(); i++) {
                    params.api.getDisplayedRowAtIndex(i)?.setExpanded(true);
                }
            },
            getRowId: (params: GetRowIdParams<BondEditViewModel>) => String(nullThrow(params.data).BondApplicationId),
        };

        this.gridApi = grid.createGrid(options);
    }

    private _createColumnDefinitions(): ColDef<BondEditViewModel>[] {
        return [
            { flex: 1, headerName: "Bond Company", field: "BondCompanyName", cellClass: "readonly-cell" },
            { flex: 1, headerName: "Bond Type", field: "BondType", cellClass: "readonly-cell" },
            { flex: 1, headerName: "Submitted By", field: "SubmittedBy", cellClass: "readonly-cell" },
            { flex: 1, headerName: "Reviewed By", field: "ReviewedBy", cellClass: "readonly-cell" },
            { flex: 1, headerName: "Posted By", field: "PostedBy", cellClass: "readonly-cell" },
            {
                flex: 1, headerName: "Posted Date", field: "PostedDateTime", cellClass: "readonly-cell",
                valueFormatter: this._formatDateField
            }
        ];
    }

    private _createDetailColumnDefinitions(): ColDef<BondEditDetailViewModel>[] {
        const isCellEditable = () => !this._isSaving;
        const getCellClass = (params: CellClassParams) =>
            `editable-cell ${this._hasCellValueChanged(params) ? "changed-cell" : ""}`;
        return [
            { flex: 1, headerName: "Bond Number", field: "BondApplicationDetailId", cellClass: "readonly-cell" },
            { flex: 1, headerName: "Degree", field: "OffenseDegree", cellClass: "readonly-cell" },
            {
                flex: 3,
                headerName: "Offense Description",
                field: "OffenseDesc",
                cellClass: getCellClass,
                editable: isCellEditable
            },
            {
                flex: 1,
                headerName: "Bond Amount",
                field: "BondAmt",
                cellClass: getCellClass,
                cellDataType: "number",
                valueFormatter: (params) => this._pageTools.formatNumberToDollarAmount(params.value),
                cellEditor: CurrencyCellEditor,
                cellEditorParams: <ICurrencyCellEditorParams>{ min: 0 },
                editable: isCellEditable
            },
            {
                flex: 1,
                headerName: "Court Name",
                field: "CourtName",
                cellClass: getCellClass,
                cellEditor: "agSelectCellEditor",
                valueFormatter: (params: ValueFormatterParams<BondEditDetailViewModel>) => {
                    if (params.value === null || params.value === undefined)
                        return this._defaultOption;
                    return params.value;
                },
                cellEditorSelector: (params: ICellEditorParams<BondEditDetailViewModel>) => {
                    return <CellEditorSelectorResult>{
                        component: "agSelectCellEditor",
                        params: <ISelectCellEditorParams<string>>{
                            values: this._courtOptions,
                        }
                    }
                },
                valueSetter: (params) => {
                    if (params.newValue === this._defaultOption) {
                        params.data.CourtName = null;
                    } else {
                        params.data.CourtName = params.newValue;
                    }
                    return true;
                },
                editable: isCellEditable
            },
            {
                flex: 1,
                headerName: "Court Date",
                field: "CourtDate",
                cellClass: getCellClass,
                cellDataType: "dateString",
                cellEditor: 'agDateStringCellEditor',
                cellEditorParams: (params: ICellEditorParams<BondEditDetailViewModel>) => {
                    return {
                        min: this._convertDateFormat(params.data.ArrestDate)
                    }
                },
                editable: isCellEditable
            },
            {
                flex: 1,
                headerName: "Arrest Date",
                field: "ArrestDate",
                cellClass: getCellClass,
                cellDataType: "dateString",
                cellEditor: 'agDateStringCellEditor',
                cellEditorParams: (params: ICellEditorParams<BondEditDetailViewModel>) => {
                    return {
                        max: this._convertDateToString(params.data.CourtDate)
                    }
                },
                editable: isCellEditable
            },
            {
                flex: 1,
                headerName: "Arrest Time",
                field: "ArrestTime",
                cellClass: getCellClass,
                cellDataType: "timeString",
                cellEditor: TimeStringCellEditor,
                editable: isCellEditable,
            },
            {
                flex: .5,
                headerName: "",

                minWidth: 140,
                cellRenderer: ButtonRenderer,
                cellRendererParams: <ButtonRendererParams<BondEditDetailViewModel>>{
                    value: {
                        buttons: [
                            {
                                onClick: (params) => this._cancelEdit(params),
                                text: "Cancel",
                                color: "btn-danger",
                                disabled: false

                            },
                            {
                                onClick: (params) => this._saveEdit(params),
                                text: "Save",
                                color: "btn-success",
                                disabled: false
                            }
                        ]
                    }
                }
            }
        ];

    }

    private _formatDateField(params: ValueFormatterParams<any, string>): string {
        return this._dateTimeFormatter.getTenantFormattedDate(nullThrow(params.value));
    }

    private _hasCellValueChanged(params: CellClassParams<BondEditDetailViewModel>): boolean {
        const detailData = nullThrow(params.data);
        const field = <keyof BondEditDetailViewModel>params.colDef.field;
        const originalData = nullThrow(this._getOriginalDetailData(detailData.BondApplicationDetailId));
        return !this._compareData(originalData, detailData, field);
    }

    private _getOriginalDetailData(detailId: number): BondEditDetailViewModel | undefined {
        return this._originalDetailData.find(
            (x) => x.BondApplicationDetailId === detailId
        );
    }

    private _cancelEdit(params: ICellRendererParams<BondEditDetailViewModel>) {
        params.api.stopEditing();
        this._dispatchErrorEvent("");
        const detailData = nullThrow(params.api.getRowNode(params.node.id!)?.data);
        const detailId = detailData.BondApplicationDetailId;
        const originalData = this._getOriginalDetailData(detailId);
        if (originalData) {
            Object.assign(detailData, originalData);
            params.api.refreshCells({ rowNodes: [params.node] });
        }
    }

    private _saveEdit(params: ICellRendererParams<BondEditDetailViewModel>) {
        params.api.stopEditing();
        const detailData = nullThrow(params.api.getRowNode(params.node.id!)?.data);
        const detailId = detailData.BondApplicationDetailId;
        const originalData = nullThrow(this._getOriginalDetailData(detailId));

        let isSame = true;
        for (const key of Object.keys(originalData)) {
            isSame = this._compareData(originalData, detailData, <keyof BondEditDetailViewModel>key);
            if (!isSame) break;
        }
        if (isSame) {
            return;
        }
        this._setIsSaving(true);
        this._dispatchErrorEvent("");
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/Application/Edit/UpdateBondApplicationDetails",
            detailData,
            (response, isSuccess) => this._onSaveEditComplete(response, isSuccess, params)
        );
    }

    private _onSaveEditComplete(
        response: string,
        isSuccess: boolean,
        params: ICellRendererParams<BondEditDetailViewModel>
    ) {
        const detailData = nullThrow(params.api.getRowNode(params.node.id!)?.data);
        const detailId = detailData.BondApplicationDetailId;
        if (!isSuccess) {
            this._dispatchErrorEvent(`Failed to update the document for Bond Number: ${detailId}.`);
            this._setIsSaving(false);
            return;
        }
        const originalData = this._getOriginalDetailData(detailId);
        if (originalData) {
            Object.assign(originalData, detailData);
        }
        params.api.refreshCells({ rowNodes: [params.node], force: true });
        this._setIsSaving(false);

        const bondDocumentId = detailData.BondDocumentId;
        window.open(`/Reports/Download/${bondDocumentId}`, "_blank", "noopener,noreferrer")
    }

    private _setIsSaving(value: boolean) {
        this._pageTools.toggleTriggers(this.parentElement!, value);
        this._isSaving = value;
        this._originalDetailData.forEach((detail) => {
            this._setButtonsDisabledState(String(detail.BondApplicationDetailId), value);
        });
        this.gridApi?.refreshCells({ force: true });
    }

    private _dispatchErrorEvent(errorMessage: string) {
        const event = new CustomEvent("edit-error", {
            detail: <ErrorDetailObject>{ error: errorMessage },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(event);
    }

    private _setButtonsDisabledState(rowId: string, disabled: boolean) {
        const buttons = this.getButtons(rowId);
        if (disabled) {
            buttons.cancel.setAttribute("disabled", "true");
            buttons.save.setAttribute("disabled", "true");
        } else {
            buttons.cancel.removeAttribute("disabled");
            buttons.save.removeAttribute("disabled");
        }
    }

    public getButtons(rowId: string): { cancel: ActionButton; save: ActionButton } {
        const rowElement = nullThrow(this.shadowRoot?.querySelector(`[row-id="${rowId}"]`));
        const cancelButton = nullThrow(rowElement.querySelector<ActionButton>(".btn-danger"));
        const saveButton = nullThrow(rowElement.querySelector<ActionButton>(".btn-success"));
        return { cancel: cancelButton, save: saveButton };
    }

    private _convertDateFormat(dateString: string | null): string | null {
        const dateStringDataDefinition = <any>getDefaultDateStringDataDefinition();

        const date = dateStringDataDefinition.dateParser(dateString);

        return this._convertDateToString(date);
    }

    private _convertDateToString(date: Date | null): string | null {
        // Safe-guard in case we get called with a string
        if (typeof date === "string")
            return this._convertDateFormat(date);

        if (date == null) {
            return null;
        }

        let month: string | number = date?.getMonth() + 1;
        let day: string | number = date?.getDate();
        let year = date?.getFullYear();

        if (day <= 9)
            day = "0" + day;

        if (month <= 9)
            month = "0" + month;

        return `${year}-${month}-${day}`;
    }

    private _compareData(originalData: BondEditDetailViewModel, newData: BondEditDetailViewModel, field: keyof BondEditDetailViewModel): boolean {
        if (field === "ArrestTime") {
            const [newHour, newMinute] = newData["ArrestTime"]!.split(":");
            const [originalHour, originalMinute] = originalData["ArrestTime"]!.split(":");
            return parseInt(originalHour) === parseInt(newHour) && originalMinute === newMinute;
        } else if (field === "CourtDate") {
            const courtDateField = originalData[field];
            let convertedDate = this._convertDateToString(courtDateField);
            const newDate = this._convertDateToString(newData["CourtDate"]);
            return (convertedDate === newDate);
        } else {
            return originalData[field] === newData[field];
        }
    }
}

customElements.define("edit-bond-grid", EditBondGrid);
