import bootstrap from './../../scss/bootstrap-custom.scss';
import bootstrap_Table from 'bootstrap-table/dist/bootstrap-table.css';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { dollarFieldFormatter, sortData } from '../BootstrapTableExtensions.js';
import template from './BootstrapTable.html';
import { printPageBuilder } from './../JavaScriptFunctions.js';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import './BootstrapTableFilter';
import * as XLSX from 'xlsx/xlsx.mjs';
import 'bootstrap-table/dist/extensions/print/bootstrap-table-print';
import 'tableexport.jquery.plugin/tableExport.min.js';
import pdfMake from 'pdfmake/build/pdfmake.min.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class BootstrapTable extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(
            this,
            template,
            [bootstrap, bootstrap_Table, fontawesome],
            ['table']
        );

        this._table = this.shadowRoot.getElementById('table');
        this._errorMessage = this.shadowRoot.getElementById('bootstrap-error-message');
        this._sortField = '';
        this._sortDataType = '';
        this._sortOrder = 'none';
        // To assign the imported function from extension file
        this._dollarFieldFormatter = dollarFieldFormatter;
        /**
         * Filters array - specs
         * - Each field filtered on will have the fieldName in the key
         * - Value could be another object of the following type:
         * - {
         *     type: values | equals | starts-with | greater-than, etc,
         *     dataType: number | text | date
         *     value: string or number, // applies for all except 'values'
         *     value2: string or number, // applies only for 'between' type
         *     values: [string or number], // applies only for 'values' type
         *   }
         */
        this._filters = {};

        /**
         * Table Configuration - expected format:
         *  {
         *      mainTableUrl: "API url to fetch the main table data",
         *      subTableUrl: "API url to fetch the sub table data",
         *      uniqueId: "Unique FieldInternalKeyName",
         *      tableColumns: [
         *          {
         *              title: "Field Name",
         *              field: "FieldInternalKeyName",
         *              type: "Text" or "Number" or "Date" or "SafeHTML", // optional - defaults to 'Text'. SafeHTML allows to add buttons
         *              escape: true, // Set to true for all except SafeHTML types
         *          },
         *          ....
         *      ],
         *      subTableColumns: [
         *          {
         *              title: "Field Name",
         *              field: "FieldInternalKeyName",
         *              formatter: "Field Formatter Name"
         *          },
         *          ....
         *      ],
         *      pageSize: 10, // Count of rows to be shown in a page
         *      detailView: true, // Boolean to show/hide child rows
         *      mainTableHeight: 500, // Height of main table
         *      print: true, // Boolean to enable/disable print feature
         *      export: true, // Boolean to enable/disable export feature
         *  }
         */
        this.bootstrapTableConfig = {};

        /**
         * Table Data - expected format:
         * [
         *   {
         *      FieldInternalKeyName1: "Field Value",
         *      FieldInternalKeyName2: "Field Value",
         *      FieldInternalKeyName3: "Field Value",
         *      FieldInternalKeyName4: "Field Value",
         *      ....
         *   },
         *   ....
         * ]
         */
        this.tableData = [];

        /**
         * Table Full Data - expected format:
         * [
         *   {
         *      FieldInternalKeyName1: "Field Value",
         *      FieldInternalKeyName2: "Field Value",
         *      FieldInternalKeyName3: "Field Value",
         *      FieldInternalKeyName4: "Field Value",
         *      ....
         *   },
         *   ....
         * ]
         */
        this.tableFullData = [];
        this.tableFilteredData = [];
        this.smartSearch = false;
        this.filteredByConditionData = [];
        this.tableColumns = [];
        this.tableHeaders = [];
        this.formatters = {};

        this._prevTop = 0;
        this.pageNo = 1;
        this.pageSize = 10;
        this.totalRecords = 0;
        this._openDropdown = '';
        this.ignoreColumn = [];
        this.printEnabled = false;
        this.exportEnabled = false;
        this.exportFilename = '';
        this.detailView = false;
        this.detailViewFormatter = null;
        this.rowStyleFormatter = null;
        this.uniqueId = '';
        this.mainTableHeight = 500;
        this.tableClasses = 'table table-sm table-bordered table-hover main-table table-striped';
        this.paginationDetails = false;
    }

    connectedCallback() {
        // Listen to closedropdown event and close all the dropdowns
        this._table.addEventListener('closedropdown', () => this._closeAllDropdowns());
        // Listen to applyfilter event and call the _applyFilter()
        this._table.addEventListener('applyfilter', e => this._applyFilter(
            (e.detail != undefined && e.detail.fieldName != undefined) ? e.detail.fieldName : '',
            (e.detail != undefined && e.detail.filter != undefined) ? e.detail.filter : null,
            (e.detail != undefined && e.detail.isApplyFilter != undefined) ? e.detail.isApplyFilter : false
        ));
        // Listen to getuniquevalues event and call the _getUniqueValues()
        this._table.addEventListener('getuniquevalues', e => this._getUniqueValues(
            (e.detail != undefined && e.detail.fieldName != undefined) ? e.detail.fieldName : '',
            (e.detail != undefined && e.detail.fieldType != undefined) ? e.detail.fieldType : ''
        ));
    }

    updateCellByUniqueId(updateObject) {
        const uniqueId = this.bootstrapTableConfig['uniqueId'];
        // sometimes we might be comparing strings to ints and need them to match
        // ReSharper disable once CoercedEqualsUsing
        const row = this.tableFullData.find(x => x[uniqueId] == updateObject.id);
        row[updateObject.field] = updateObject.value;
        $(this._table).bootstrapTable('updateCellByUniqueId', updateObject);
    }

    getSelections() {
        return $(this._table).bootstrapTable('getSelections');
    }

    destroyTable() {
        $(this._table).bootstrapTable('destroy');
    }

    showLoading() {
        $(this._table).bootstrapTable('showLoading');
    }

    /**
     * Initialize table data
     * @param {Object} bootstrapTableConfig
     * @param {Array} tableData
     */
    initTableData({ bootstrapTableConfig, tableData = [] }) {
        this.tableFullData = tableData;
        this.bootstrapTableConfig = bootstrapTableConfig;
        if (this._getTableConfig('pageSize'))
            this.pageSize = this._getTableConfig('pageSize');

        this.tableColumns = this._getTableConfig('tableColumns') ? this._getTableConfig('tableColumns') : [];
        this.printEnabled = this._getTableConfig('print');
        this.exportEnabled = this._getTableConfig('export');
        this.exportFilename = this._getTableConfig('exportFilename') ? this._getTableConfig('exportFilename') : 'BootstrapTableExport';
        this.uniqueId = this._getTableConfig('uniqueId');
        this.detailView = this._getTableConfig('detailView');
        this.detailViewFormatter = this._getTableConfig('detailViewFormatter');
        this.rowStyleFormatter = this._getTableConfig('rowStyleFormatter');
        this.toolbar = this._getTableConfig('toolbar');
        this.columnFilters = this._getTableConfig('columnFilters');
        this.sortable = this._getTableConfig('sortable');
        this.tableAlertMessage = this._getTableConfig('tableAlertMessage');
        this.mainTableHeight = this._getTableConfig('mainTableHeight');
        this.restoreFilters = this._getTableConfig('restoreFilters') ? this._getTableConfig('restoreFilters') : false;
        this.tableName = this._getTableConfig('tableName') ? this._getTableConfig('tableName') : false;
        this.tableClasses = this._getTableConfig('tableClasses') ? this.tableClasses + ' ' + this._getTableConfig('tableClasses') : this.tableClasses;
        this.paginationDetails = this._getTableConfig('paginationDetails') ? this._getTableConfig('paginationDetails') : false;
        this.onCheck = this._getTableConfig('onCheck');
        this.onUncheck = this._getTableConfig('onUncheck');
        this.onUncheckAll = this._getTableConfig('onUncheckAll');
        this.onCheckAll = this._getTableConfig('onCheckAll');
        this.virtualScroll = this.bootstrapTableConfig['virtualScroll'] ?? true; // don't use helper method cause we want to know if it was undefined
        this.virtualScrollItemHeight = this.bootstrapTableConfig['virtualScrollItemHeight'];
        this.checkboxHeader = this.bootstrapTableConfig['checkboxHeader'] ?? true;
        this.onPostBody = this.bootstrapTableConfig['onPostBody'];
        // create the tableHeaders with field name as the key and title as the value when export feature is enabled
        if (this.exportEnabled) {
            this.tableColumns.forEach((column) => {
                this.tableHeaders[column.field] = column.title;
            });
        }
        this._getTableData();
        if (!this.tableAlertMessage) {
            this._errorMessage.clearMessage();
        }
    }

    disconnectedCallback() {
        // nothing to do
    }

    /**
     * Get data from API when dataUrl is provided
     */
    _getTableData() {
        if (this._getTableConfig('mainTableUrl')) {
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest('GET', this._getTableConfig('mainTableUrl'), null, this._getTableDataCallback.bind(this));
        } else {
            this._loadTableData()
        }  
    }

    _getTableDataCallback(response, isSuccess) {
        if (isSuccess) {
            this.tableFullData = JSON.parse(response);
            this._loadTableData();
        } else {
            this._errorMessage.showErrorMessage("There was a problem completing your request, please check your internet connection and try again. If this problem persists contact <contact-link>eBONDS\u2122 support</contact-link>.", true);    
        }
    }

    // Add filter buttons to table Headers and pointer class to filterable/sortable column
    _alterTableColumns(addFilter, addSort) {
        if (!addFilter && !addSort)
            return;

        this.tableColumns.forEach((value, index, array) => {
            if (value['type'] !== 'SafeHTML') {
                if (array[index]['class'] === undefined)
                    array[index]['class'] = 'pointer';
                else
                    array[index]['class'] += ' pointer';
                    
                array[index]['title'] = this._buildColumnTitleHtml(value, addFilter, addSort);
            }
            // if printIgnore is true, then push it ignoreColumn so that it will be ignored while exporting
            if (value['printIgnore'] && this.exportEnabled) {
                this.ignoreColumn.push(value['field']);
            }
            this.formatters[value['field']] = value['formatter'];
        });
    }

    _buildColumnTitleHtml(value, addFilter, addSort) {
        let title = "";
        if (value.checkbox && this.checkboxHeader) {
            title += `<div class="d-flex justify-content-between">`;
            if (addSort) {
                title += `<i class='text-primary invisible-sort-indicator pl-1 fa'></i>`; // Add a hidden item to let the checkbox be centered
            }
            this.checkboxHeader = false; // We're putting the header in and need to not have bootstrap-table do it
            value.showSelectTitle = true; // But we need it to show our title
            title += '<label><input name="btSelectAll" type="checkbox" style="margin-left: 2px !important;margin-right: 2px !important;"/><span></span></label>';
        }
        else {
            title += `<div class="d-flex justify-content-between">`;
            title += `<span>${value["title"]}</span>`;
        }
        title += "<div>";
        if (addSort) {
            title += `<i class='text-primary sort-indicator pl-1 fa' data-field='${value["field"]}' data-type='${value["type"]}'></i>`;
        }
        if (addFilter && !value.checkbox) {
            title += `<bootstrap-table-filter fieldName='${value["field"]}' fieldType='${value["type"]}'></bootstrap-table-filter></div>`;
        }
        title += "</div>";
        return title;
    }

    /**
     * Initialize bootstrapTable and add eventlisteners
     */
    _loadTableData() {
        const tableFullData = this.tableFullData.slice();
        this.totalRecords = tableFullData.length;
        this.tableFilteredData = tableFullData;
        this.tableData = tableFullData;
        // Alter the table columns before initialising the table
        this._alterTableColumns(this.columnFilters, this.sortable);

        const isAndroidDevice = navigator.userAgentData && navigator.userAgentData.platform === 'Android';
        $(this._table).bootstrapTable({
            columns: this.tableColumns,
            data: this.tableData,
            classes: this.tableClasses,
            uniqueId: this.uniqueId,
            detailView: this.detailView,
            height: this.mainTableHeight,
            detailFormatter: (index, row, $element) => this.detailViewFormatter ? this.detailViewFormatter.bind(this, index, row, $element) : this._detailFormatter(index, row, $element),
            rowStyle: this.rowStyleFormatter ? this.rowStyleFormatter : {},
            formatLoadingMessage: () => { return 'Loading' },
            showPrint: this.printEnabled && !isAndroidDevice,
            toolbar: this.toolbar ? this.shadowRoot.querySelector('.toolbar') : undefined,
            toolbarAlign: this.toolbar ? 'right' : undefined,
            virtualScroll: this.virtualScroll,
            virtualScrollItemHeight: this.virtualScrollItemHeight,
            onCheck: this.onCheck,
            onUncheck: this.onUncheck,
            onUncheckAll: this.onUncheckAll,
            onCheckAll: this.onCheckAll,
            checkboxHeader: this.checkboxHeader,
            onPostBody: this.onPostBody,
            printPageBuilder: function (table) {
                return printPageBuilder(table);
            }
        });

        // Append data to table when scrolled to the end of the table
        // Close all dropdown on clicking on the tbody
        this._table.querySelector('tbody').addEventListener('click', () => this._closeAllDropdowns(), true);
        // Close export dropdown on clicking anywhere when export dropdown is enabled
        if (this.exportEnabled)
            document.addEventListener('click', () => this._closeExportDropdown(), true);
        this._table.querySelectorAll('thead > tr > th').forEach((tableColumn) => {
            // Add event listener to sort on clicking the column
            tableColumn.addEventListener('click', (e) => this._doSortBy(e.currentTarget));
        });

        // Close dropdown on clicking on filter icon the second time
        this._table.querySelectorAll('thead > tr > th bootstrap-table-filter').forEach((tableColumn) => {
            tableColumn.shadowRoot.querySelector('.dropdown-toggle-button').addEventListener('click', (e) => this._dropdownClick(e));
        });

        // Handle export btn clicks and trigger the respective export after loading the table data
        if (this.exportEnabled)
            this._exportTable();
        if (this.restoreFilters && this.tableName) {
            this.settings = this._getFromStorage(this.tableName);
            this._table.addEventListener('applyfilter', () => this._setInStorage(this.tableName, this.settings));
            this._table.addEventListener('click', () => this._setInStorage(this.tableName, this.settings));
        }

        // Update pagination details
        this._paginationDetails();
    }

    // Export the table to CSV, PDF, Excel
    _exportTable() {
        let exportWrapper = this.shadowRoot.querySelector('.toolbar > .export-btn-wrapper');
        exportWrapper.classList.remove('d-none');
        $(exportWrapper).find('.dropdown-toggle').dropdown();
        exportWrapper.querySelectorAll('.export-btn').forEach(exportBtn => {
            exportBtn.addEventListener('click', (e) => {
                const options = $(this._table).bootstrapTable('getOptions');
                const wasVirtualScroll = options.virtualScroll;
                if (wasVirtualScroll) {
                    options.virtualScroll = false;
                    $(this._table).bootstrapTable('refreshOptions', options);
                }

                e.target.closest('.dropdown-menu').classList.remove('show');
                const exportType = e.target.closest('.export-btn').getAttribute('data-export-type');
                this._loadData();
                // Collapse all rows else exported files will have child rows mapped to parent columns headers
                $(this._table).bootstrapTable('collapseAllRows');
                try {
                    if (exportType === 'excel') {
                        const workbook = XLSX.utils.book_new();
                        let sheet = XLSX.utils.table_to_sheet(this._table, { cellStyles: true, cellDates: true });
                        // Get the correct table data
                        let tableData = this.tableFullData;
                        if (this._filters)
                            tableData = this.tableFilteredData;
                        sheet["!cols"] = this._getExcelColumns(tableData);
                        this._setCellTypes(this._table, tableData, sheet);
                        XLSX.utils.book_append_sheet(workbook, sheet);
                        XLSX.writeFile(workbook, this._getExportNameWithDateTime(this.exportFilename) + ".xlsx", { cellDates: true });
                    }
                    else {
                        if (this.detailView)
                            this.ignoreColumn.push(0);
                        const exportConfig = this._getExportConfig(exportType);
                        exportConfig.ignoreColumn = this.ignoreColumn;
                        exportConfig.fileName = this._getExportNameWithDateTime(this.exportFilename);
                       // exportConfig.styles = `${styles: { headerBgColor: #FF0000 }} `;
                        $(this._table).tableExport(exportConfig);
                    }
                } finally {
                    if (wasVirtualScroll) {
                        options.virtualScroll = true;
                        $(this._table).bootstrapTable('refreshOptions', options);
                    }
                    this._reinitializeSortListeners();
                }
            });
        });
    }

    _reinitializeSortListeners() {
        this._table.querySelectorAll('thead > tr > th').forEach(tableColumn => {
            tableColumn.removeEventListener('click', this._doSortBy);
            tableColumn.addEventListener('click', (e) => this._doSortBy(e.currentTarget));
        });
        if (this._sortField !== '') {
            const sortIndicator = this._table.querySelector(`i.sort-indicator[data-field=${this._sortField}]`);
            if (sortIndicator) {
                this._sortIndication(sortIndicator);
            }
        }
    }

    _getExportNameWithDateTime(exportName) {
        return `${exportName}-${window.moment().format("YYYYMMDD-HHmm")}`;
    }

    _setCellTypes(table, data, ws) {
        const visibleColumns = $(table).bootstrapTable("getVisibleColumns");
        const numberColumnIndices = visibleColumns
            .filter(x => x.type === "Number" && x.formatter?.name !== undefined)
            .map(x => x.fieldIndex);
        const dateColumnIndices = visibleColumns.filter(x => x.type === "Date")
            .map(x => x.fieldIndex);
        const rowCount = data.length;
        numberColumnIndices.forEach(ix => {
            const columnLetter = this._getExcelColumnLetterFromTableColumnIndex(this._getExcelColumnIndex(table, ix));
            for (let i = 2 /*Excel sheet data rows start at 2*/; i < rowCount + 2 /*Offset by 2*/; i++) {
                //https://docs.sheetjs.com/docs/csf/features/nf#live-demo
                ws[`${columnLetter}${i}`].z = '"$"#,##0.00_);\\("$"#,##0.00\\)';
            }
        });
        dateColumnIndices.forEach(ix => {
            let bootstrapTableColumnIx = this._getExcelColumnIndex(table, ix);
            const columnLetter = this._getExcelColumnLetterFromTableColumnIndex(bootstrapTableColumnIx);
            for (let i = 2 /*Excel sheet data rows start at 2*/; i < rowCount + 2 /*Offset by 2 for excel row num*/; i++) {
                const wsIx = `${columnLetter}${i}`;

                // As of XLSX 0.20.2, sheetJS has gotten better at detecting and auto-parsing date columns.
                // When the bootstrap table is converted to a sheet object, date columns with string values like "2024-03-28T14:08:21.2028423"
                //  are automatically detected, given raw value (v) of an excel date number, a type (t) of Number, and a date number format (z).
                //  Prior to this version, all dates came in as strings regardless.
                //  If the bootstrap table has a formatter that outputs a date string like "3/28/2024 9:08:21 AM CDT" sheetJS does not recognize the string as a date. And treats the cell like text.
                // setting cellDates: true when the sheet is created allows auto parsed date raw values to come in as date objects https://docs.sheetjs.com/docs/csf/features/dates#how-sheetjs-handles-dates-and-times

                let date;
                const dateString = ws[wsIx].v;
                if (typeof dateString === "string") {
                    // Sample dateString: "3/28/2024 9:08:21 AM CDT"
                    const tz = dateString.split(' ')[3];
                    const dateWithTimezoneTrimmed = dateString.replace(` ${tz}`, "");
                    date = new Date(dateWithTimezoneTrimmed);
                } else if (typeof dateString === "object" && this._isValidDate(dateString)) {
                    date = dateString; // dateString is potentially a date object
                    console.error(`Bootstrap column index ${bootstrapTableColumnIx}, did not have a formatted date for export at ${window.location.href}`);
                } else
                    continue; // Leave non valid date cells as is.

                if (!window.moment(date).isValid())
                    continue; // Leave non valid date cells as is.

                //https://docs.sheetjs.com/docs/csf/features/dates/#how-sheetjs-handles-dates-and-times
                // Couldn't get the cellDates option on the table_to_sheet method to automatically
                //  convert the cell type to date and value to date object, so it was manually implemented.
                ws[wsIx].t = "d";
                // date should not be null here as moment(null).isValid() is false.
                ws[wsIx].v = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));

                //https://docs.sheetjs.com/docs/csf/features/nf/#values-and-formatting
                ws[wsIx].z = `m/d/yyyy h:mm:ss AM/PM "${window.DateFormatter.getUserTimeZone(date)}"`;
            }
        });
    }

    // https://stackoverflow.com/a/44198641
    _isValidDate(date) {
        return !!date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    _getExcelColumnIndex(table, bootstrapTableColumnIx) {
        // Account for the fact that the expand column shows up in the spreadsheet.
        const expandColumnExists = $(table).bootstrapTable("getOptions").detailView;
        return expandColumnExists ? bootstrapTableColumnIx + 1 : bootstrapTableColumnIx;
    }

    // https://stackoverflow.com/a/64456745
    _getExcelColumnLetterFromTableColumnIndex(index) {
        let letters = "";
        while (index >= 0) {
            letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[index % 26] + letters;
            index = Math.floor(index / 26) - 1;
        }
        return letters;
    }

    /**
     * 
     * @param {Object} tableData 
     * Return the column with hidden and width properties in order to generate the Excel
     */
    _getExcelColumns(tableData) {
        let objectMaxLength = {};
        let columns = [];
        // Hide the first column when detailView is enabled
        if (this.detailView)
            columns.push({ hidden: true });
        // Loop through the tableData and find the max length of column content
        for (const element of tableData) {
            Object.entries(element).forEach(([header, rowValue]) => {
                if (objectMaxLength[header] === undefined)
                    objectMaxLength[header] = 0;
                if (typeof rowValue === 'number') {
                    objectMaxLength[header] = 10;
                }
                else {
                    const len = rowValue ? rowValue.length : 0;
                    objectMaxLength[header] = Math.max(objectMaxLength[header], len);
                }
            });
        }
        // Iterate over the table header since we need to maintain the order
        Object.entries(this.tableHeaders).forEach(([key, value]) => {
            let column = {
                wch: Math.max(value.length, objectMaxLength[key])
            };
            if (this.ignoreColumn.includes(key))
                column['hidden'] = true;
            columns.push(column);
        });
        return columns;
    }

    /**
     * Load the full table data or filtered data based on filters applied
     */
    _loadData() {
        let data = this.tableFullData;
        if (this._filters)
            data = this.tableFilteredData;
        $(this._table).bootstrapTable('load', data);
    }

    /**
     * Return the table config based on key
     * @param {String} type
     */
    _getTableConfig(type) {
        let config = false;
        if (typeof this.bootstrapTableConfig[type] !== 'undefined' && this.bootstrapTableConfig[type]) {
            config = this.bootstrapTableConfig[type];
        }
        return config;
    }

    /**
     * Return the export config based on the type
     * @param {String} type
     */
    _getExportConfig(type) {
        let config = {};
        switch (type) {
            case 'csv':
                config = {
                    type: 'csv',
                    excel: { fileFormat: 'csv' },
                    escape: 'false'
                };
                break;
            case 'pdf':
                config = {
                    type: 'pdf',
                    pdfmake: {
                        enabled: true,
                        docDefinition: {
                            pageOrientation: 'landscape',
                            styles: {
                                header: {
                                    background: '#ADD8E6',
                                    fillColor: '#ADD8E6',
                                    decorationColor: '#ADD8E6',
                                },
                            },
                        },
                    }
                };
                break;
        }
        return config;
    }

    /**
     * Close dropdown while clicking on the same filter icon the second time
     * @param {Event} e
     */
    _dropdownClick(e) {
        const currentTarget = e.target.id;
        if (currentTarget == this._openDropdown) {
            this._closeAllDropdowns();
        }
        this._openDropdown = currentTarget;
    }

    /**
     * Create the SubTable for the given row
     * @param {Number} index
     * @param {Object} row
     * @param {HTMLElement} $element
     */
     _detailFormatter(index, row, $element) {
        const subTable = `<table id="bootstrapSubTable-${index}"></table>`;
        $element.html(subTable);
         const $table = $element.find('table');
        const uniqueId = this.uniqueId;
         if (this._getTableConfig('subTableUrl')) {
             const subTableUrl = this._getTableConfig('subTableUrl');
             const xhrWrapper = new XhrWrapper();
             xhrWrapper.makeRequest('GET', subTableUrl + encodeURIComponent(row[`${uniqueId}`]), null, this._detailFormatterCallback.bind(this, $table, $element));
         }
     }

    _detailFormatterCallback($table, $element, response, isSuccess) {
        if (isSuccess) {
            const subTableDataResponse = JSON.parse(response);
            $table.bootstrapTable({
                columns: this._getTableConfig('subTableColumns'),
                data: subTableDataResponse,
                pagination: true,
                pageSize: 5,
                paginationHAlign: 'left',
                paginationDetailHAlign: 'left',
                // by default, pagesize dropdown doesn't work since it is added dynamically,
                // so to fix this we need to bind it to dropdown everytime the table is reset
                onResetView: function () {
                    $element.find('.dropdown-toggle').dropdown();
                },
                classes: 'table table-sm table-striped table-bordered'
            });
        } else {
            this._errorMessage.showErrorMessage("There was a problem completing your request, please check your internet connection and try again. If this problem persists contact <contact-link> eBONDS\u2122 support</contact-link>.", true);
        }
    }

    _calculateUniqueValues(tableData, fieldName, fieldType) {
        let values = [];
        let uniqueValues = [];
        let sortedData = sortData(tableData, fieldName, fieldType, 'asc');
        let column = this.tableColumns.find((x) => x.field === fieldName);
        sortedData.forEach((row) => {
            if (values.indexOf(row[fieldName]) === -1) {
                // Convert null to '' so that they can be filtered
                const value = row[fieldName] !== null ? row[fieldName] : '';
                let formattedValue = value;
                values.push(row[fieldName]);
                if (this.formatters[fieldName] !== undefined) {
                    const formatter = this.formatters[fieldName];
                    const formatted = formatter.call(this, value, row, 0, fieldName);
                    formattedValue = formatted !== undefined ? formatted : value;
                    if (column.filterFormatter) {
                        formattedValue = column.filterFormatter(formattedValue);
                    }
                }
                uniqueValues.push({ 'original': value, 'formatted': formattedValue });
            }
        });
        return uniqueValues;
    }

    /**
     * Return the distinct values for the given column
     * @param {String} fieldName
     * @param {String} fieldType
     */
    _getUniqueValues(fieldName, fieldType) {
        if (fieldName !== '' && fieldType !== '') {
            const filterComponent = this._table.querySelector(`bootstrap-table-filter[fieldname=${fieldName}]`);
            const filters = this._filters;
            // On initial load, get all distinct values from entire table data
            let tableData = this.tableFullData.slice();
            // When any of the filters are applied, get all distinct values from filtered table data
            if (!this._isEmptyObj(filters)) {
                tableData = this.filteredByConditionData.slice();
            }

            const uniqueValues = this._calculateUniqueValues(tableData, fieldName, fieldType);

            // Dispatch uniquevalues event to the specific filter component to prevent dispatching to unwanted filter components
            const uniqueValuesEvent = new CustomEvent('uniquevalues', {
                bubbles: true,
                cancelable: false,
                composed: true,
                detail: {
                    values: uniqueValues
                }
            });
            if (filterComponent !== null && filterComponent != undefined && filterComponent.shadowRoot !== null)
                filterComponent.shadowRoot.dispatchEvent(uniqueValuesEvent);
            return uniqueValues;
        }
        return null;
    }

    /**
     * Check if object is not empty
     * @param {Object} obj
     */
    _isEmptyObj(obj) {
        return Object.keys(obj).length === 0;
    }

    /**
     * Save sort options provided as input arguments, and refresh the table data in sorted fashion
     * @param {Object} column
     */
    _doSortBy(column) {
        let sortNode = null;
        // Click can be on the header/sort icon, handle it accordingly
        if ((column.matches('.pointer') || column.matches('.th-inner') || column.matches('.d-flex')) && column.querySelector('.sort-indicator') !== null) {
            sortNode = column.querySelector('.sort-indicator');
        }
        if (column.matches('.sort-indicator')) {
            sortNode = column;
        }
        if (sortNode !== null && sortNode != undefined) {
            const field = sortNode.getAttribute('data-field');
            const dataType = sortNode.getAttribute('data-type');
            // By default, we sort in asc order else toggle the sort order
            if (this._sortField !== field) {
                this._sortOrder = 'asc';
            }
            else {
                const sortOrder = {
                    'none': 'asc',
                    'asc': 'desc',
                    'desc': 'none'
                };
                this._sortOrder = sortOrder[this._sortOrder];
            }
            this._sortField = '';
            this._sortDataType = '';
            if (this._sortOrder !== 'none') {
                this._sortField = field;
                this._sortDataType = dataType;
            }
            this._sortIndication(sortNode);
            this.refreshTableData();
        }
    }

    // Close export dropdown on click anywhere
    _closeExportDropdown() {
        if (this.shadowRoot.querySelector('.export-btn-wrapper .dropdown-menu') !== undefined)
            this.shadowRoot.querySelector('.export-btn-wrapper .dropdown-menu').classList.remove('show');
    }

    // Close all currently open filter dropdowns
    _closeAllDropdowns() {
        this._table.querySelectorAll('bootstrap-table-filter').forEach((bootstrapTableFilterElement) => {
            bootstrapTableFilterElement.shadowRoot.querySelectorAll('.dropdown-menu').forEach(item => {
                item.classList.remove('show');
                item.parentElement.classList.remove('show');
            });
        });
        this._openDropdown = '';
    }

    /**
     * Refresh table data based on the current set of filters
     */
    refreshTableData(pageNo = null) {
        const filters = this._filters;
        const tableData = this.tableFullData;

        // Get the filter by condition data separately in order to maintain the checkboxes
        this.filteredByConditionData = tableData.filter((row) => {
            return this._applyFilterByCondition(filters, row);
        });
        const filteredByConditionData = this.filteredByConditionData.slice();
        let tableFilteredData = [];
        // Apply filter by value to filter by condition to get end filtered results
        tableFilteredData = filteredByConditionData.filter((row) => {
            return this._applyFilterByValue(filters, row);
        });
        this.tableFilteredData = sortData(tableFilteredData, this._sortField, this._sortDataType, this._sortOrder);
        this.totalRecords = this.tableFilteredData.length;

        $(this._table).bootstrapTable('load', this.tableFilteredData);
        // Update pagination details
        this._paginationDetails();
    }

    // Apply filter by condition
    _applyFilterByValue(filters, row) {
        return Object.entries(filters).every(([fieldName, filterValue]) => {
            let fieldResult = true; // by default, assume that the record passes filters
            if (filterValue['byValue'] !== undefined) {
                // Convert null to '' so that they can be filtered
                let rowValue = row[fieldName] !== null ? row[fieldName] : undefined;
                if (filterValue['dataType']?.toLowerCase() === 'number')
                    rowValue = Number(rowValue);
                fieldResult = filterValue['byValue']['values'].includes(rowValue);
            }
            return fieldResult;
        });
    }

    // Apply filter by condition
    _applyFilterByCondition(filters, row) {
        return Object.entries(filters).every(([fieldName, filterValue]) => {
            let fieldResult = true; // by default, assume that the record passes filters

            // Apply the filter by condition
            if (filterValue['byCondition'] !== undefined && filterValue['byCondition'] !== null) {
                const dataType = filterValue['dataType'];
                let type = filterValue['byCondition']['type'];
                let filterValue1 = filterValue['byCondition']['value'];
                let filterValue2 = filterValue['byCondition']['value2'];
                let fieldValue = row[fieldName];

                if (dataType === 'date') {
                    filterValue1 = window.moment(filterValue1).toDate();
                    if (filterValue2) {
                        filterValue2 = window.moment(filterValue2).toDate();
                    }
                    fieldValue = window.moment.utc(fieldValue).toDate();
                    // Special case for equals and dates because you can only pick minutes on the GUI
                    if (type === 'equals') {
                        type = 'between';
                        filterValue2 = new Date(filterValue1.getTime() + 1000 * 60);
                    }
                } else if (dataType === 'text') {
                    filterValue1 = filterValue1.toLowerCase();
                    fieldValue = fieldValue.toLowerCase();
                }

                switch (type) {
                    case 'equals':
                        fieldResult = fieldValue == filterValue1;
                        break;
                    case 'does-not-equal':
                        fieldResult = fieldValue != filterValue1;
                        break;
                    case 'greater-than':
                    case 'after':
                        fieldResult = fieldValue > filterValue1;
                        break;
                    case 'greater-than-or-equal':
                        fieldResult = fieldValue >= filterValue1;
                        break;
                    case 'less-than':
                    case 'before':
                        fieldResult = fieldValue < filterValue1;
                        break;
                    case 'less-than-or-equal':
                        fieldResult = fieldValue <= filterValue1;
                        break;
                    case 'between':
                        fieldResult = fieldValue >= filterValue1 && fieldValue <= filterValue2;                        
                        break;
                    case 'begins-with':
                        fieldResult = fieldValue.startsWith(filterValue1);
                        break;
                    case 'ends-with':
                        fieldResult = fieldValue.endsWith(filterValue1);
                        break;
                    case 'contains':
                        fieldResult = fieldValue.includes(filterValue1);
                        break;
                    case 'does-not-contain':
                        fieldResult = fieldValue.indexOf(filterValue1) === -1;
                        break;
                }
            }
            return fieldResult;
        });
    }

    /**
     * Apply filtering on a particular field
     * @param {String} fieldName
     * @param {Object} filter
     */
    _applyFilter(fieldName, filter, isApplyFilter) {
        if (fieldName !== '' && filter !== null) {
            this._filters[fieldName] = filter;
            this._filterIndication(fieldName, isApplyFilter);
            this.refreshTableData();
            this._openDropdown = '';
        }
    }

    /**
     * Toggle the filter indicator
     * @param {String} fieldName
     * @param {Boolean} isApplyFilter
     */
    _filterIndication(fieldName, isApplyFilter = false) {
        const bootstrapTableFilters = this._table.querySelector(`bootstrap-table-filter[fieldname=${fieldName}]`);
        if (bootstrapTableFilters !== null && bootstrapTableFilters != undefined) {
            const bootstrapTableFilterIcon = bootstrapTableFilters.shadowRoot.querySelector('#bootstrap-table-filter-container a');
            bootstrapTableFilterIcon.classList.remove('text-primary', 'filter-active');
            bootstrapTableFilterIcon.classList.add('text-light');
            if (isApplyFilter) {
                bootstrapTableFilterIcon.classList.add('text-primary', 'filter-active');
                bootstrapTableFilterIcon.classList.remove('text-light');
            }
        }
    }

    /**
     * Toggle the sort indicator
     * @param {Element} element
     */
    _sortIndication(element) {
        const sortClasses = {
            none: null,
            asc: 'fa-arrow-up',
            desc: 'fa-arrow-down'
        };
        const sortClass = sortClasses[this._sortOrder];
        // Remove sort classes from all columns
        this._table.querySelectorAll('.sort-indicator').forEach(
            (item) => {
                item.classList.remove('fa-arrow-up', 'fa-arrow-down');
            }
        );
        if (sortClass !== null && this._sortField !== '') {
            // Add respective sort class to the provided element else identify the sort icon based on _sortField
            let sortNode = this._table.querySelector(`i.sort-indicator[data-field=${this._sortField}]`);
            if (element)
                sortNode = element;
            if(sortNode)
                sortNode.classList.add(sortClass);
        }

    }

    // Returns the current filter and sort options
    get settings() {
        const settings = {
            filter: this._filters,
            sort: {
                field: this._sortField,
                order: this._sortOrder,
                type: this._sortDataType
            }
        };
        return JSON.stringify(settings);
    }

    /**
     * Applies provided filter and sort options to existing grid data
     * @param {Object} settings
     */
    set settings(settings) {
        if (settings) {
            settings = JSON.parse(settings);
            if (settings.filter) {
                this._filters = this._removeInvalidFilters(settings.filter);
                // Highlight the respective filters
                this._highlightFilters();
            }
            if (settings.sort.field && settings.sort.order && settings.sort.type) {
                this._sortField = settings.sort.field;
                this._sortOrder = settings.sort.order;
                this._sortDataType = settings.sort.type;
                // Highlight the sorting icon based on the sort field
                this._sortIndication();
            }
            this.refreshTableData();
        }
    }

    _removeInvalidFilters(filters) {
        // https://stackoverflow.com/a/38750895/7412948
        return Object.keys(filters)
            .filter((fieldName) => this.tableColumns.some(x => x.field === fieldName))
            .reduce((obj, fieldName) => {
                return {
                    ...obj,
                    [fieldName]: filters[fieldName]
                };
            }, {});
    }

    _highlightFilters() {
        Object.entries(this._filters).forEach(([fieldName, filterValue]) => {
            const bootstrapTableFilters = this._table.querySelector(`bootstrap-table-filter[fieldname=${fieldName}]`);

            if (bootstrapTableFilters === undefined || bootstrapTableFilters === null)
                return;

            if (filterValue.isVisible) {
                this._filterIndication(fieldName, filterValue.isVisible);
                const filterChangedEvent = new CustomEvent('filterchanged', {
                    bubbles: true,
                    cancelable: false,
                    composed: true,
                    detail: {
                        values: filterValue
                    }
                });
                bootstrapTableFilters.shadowRoot.dispatchEvent(filterChangedEvent);
            }

            if (filterValue.byCondition != undefined) {
                bootstrapTableFilters.shadowRoot.querySelector('.first-textfield input').value = filterValue.byCondition.value == undefined ? '' : filterValue.byCondition.value;
                bootstrapTableFilters.shadowRoot.querySelector('.field-condition-type').value = filterValue.byCondition.type == undefined ? 'none' : filterValue.byCondition.type;
            }
        });
    }

    _setInStorage(key, value) {
        if (key !== "" && value !== "")
            localStorage.setItem(key, value);

    }

    _getFromStorage(key) {
        if (key !== "")
            return localStorage.getItem(key);
        else
            return "";
    }

    /**
     * Show/Update pagination count details at the bottom of the table
     */
    _paginationDetails() {
        if (this.paginationDetails) {
            const countWrapper = this.shadowRoot.getElementById('pagination-details');
            countWrapper.classList.remove('d-none');
            countWrapper.querySelector('.card-text').innerHTML = `Showing ${this.tableFilteredData.length} of ${this.tableFullData.length} rows`;
        }
    }
}
customElements.define('bootstrap-table', BootstrapTable);