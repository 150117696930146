// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum ChargeStatus {
  Available = 0,
  Completed = 1,
  HoldWithoutBond = 2,
  InBookout = 3,
  InReview = 4,
  NotBondable = 5
}