// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-details-row{padding:.25em}`, "",{"version":3,"sources":["webpack://./src/HtmlElements/Grid/BasicGrid.scss"],"names":[],"mappings":"AAAA,gBACI,aAAA","sourcesContent":[".ag-details-row {\r\n    padding: .25em;\r\n}"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
module.exports = ___CSS_LOADER_STYLE_SHEET___;
