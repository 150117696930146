import bootstrap from "./../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import {
    createColumn,
    dollarFieldFormatter,
    getRowChargeStatusCssClass
} from "./BootstrapTableExtensions";
import chargeStatus from "./Enumerations/ChargeStatus";
import { initializeHtmlElement } from "./HTMLElementExtensions";
import template from "./AdditionalChargesDialog.html";
import "./ActionButton";
import bondGroupType from "./Enumerations/BondGroupType"
import { FeatureFlag } from "../types/Library/Common/Enumerations/FeatureFlag"

class AdditionalChargesDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable]);
        this._pageTools = new PageTools();
        this._tableColumns = [];
        this._modalComponent = this.shadowRoot.querySelector("modal-component");
        this._container = this.shadowRoot.getElementById("container");
        this._table = this.shadowRoot.querySelector("#table");
        this._errorMessage = this.shadowRoot.getElementById("error-message");
        this._continueButton = this.shadowRoot.querySelector("#continue-button");
        this._cancelButton = this.shadowRoot.querySelector("#cancel-button");

        this._continue = this._continue.bind(this);
        this._cancel = this._cancel.bind(this);
    }

    connectedCallback() {
        this._continueButton.addEventListener("click", this._continue);
        this._cancelButton.addEventListener("click", this._cancel);
        this._modalComponent.addEventListener("modalClosed", this._cancel);
    }

    disconnectedCallback() {
        this._continueButton.removeEventListener("click", this._confirm);
        this._cancelButton.removeEventListener("click", this._cancel);
        this._modalComponent.removeEventListener("modalClosed", this._cancel);
    }

    openDialog(data) {
        this._model = data.Offenses;
        const hasAggregateBonds = data.Offenses.some(x => x.GroupType === bondGroupType.aggregateBondByCourt.value || x.GroupType === bondGroupType.aggregateBondByWarrant.value);
        const hasTotalBonds = data.Offenses.some(x => x.GroupType === bondGroupType.totalBond.value);

        let titleGroupType = "Grouped";
        if (hasAggregateBonds && hasTotalBonds) {
            titleGroupType = "Grouped and Total Bonds"
        } else if (hasTotalBonds) {
            titleGroupType = "Total Bonds"
        }
        const errorMessageGroupType = titleGroupType.toLowerCase();
        this._errorMessage.innerHTML = `The inmate ${data.InmateFullNameRev} has additional ${errorMessageGroupType} offenses that are not included in this application. The additional offenses are shown below.<br>`
            + "To include these offenses, click Cancel, then cancel this application and create a new one. To continue without these offenses, click Continue.";
        this._title = `Additional ${titleGroupType} Offenses for Inmate ${data.InmateFullNameRev}`;
        this._loadTableData(this._model);
        this._modalComponent.openModal(this._title, false);
    }

    _loadTableData(model) {
        const chargesTable = $(this._table);
        const columns = [];
        columns.push(createColumn("Arrest Date", "ArrestDate"));
        columns.push(createColumn("Offense Code", "OffenseCode"));
        columns.push(createColumn("Degree", "OffenseDegree"));
        columns.push(createColumn("Warrant Number", "WarrantNumber"));
        if (settings.featureFlags[FeatureFlag.CitationNumberShowsForBondApplication])
            columns.push(createColumn("Citation Number", "CitationNumber"));
        if (settings.featureFlags[FeatureFlag.CourtNameShowsForBondApplication]) 
            columns.push(createColumn("Court Name", "CourtName"));
        if (settings.featureFlags[FeatureFlag.AgencyCaseNumberShowsForBondApplication])
            columns.push(createColumn("Agency Case Number", "AgencyCaseNumber"));
        columns.push(createColumn("Offense Description", "OffenseDesc"));
        columns.push(createColumn("Bond Type", "BondType"));
        columns.push(createColumn("Bond Description", "BondDesc"));
        columns.push(createColumn("Bond Amount", "BondAmt", undefined, dollarFieldFormatter));
        columns.push(createColumn("Status", "ChargeStatus"));

        chargesTable.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered",
            rowStyle: this._rowStyleFormatter.bind(this)
        });
    }

    _rowStyleFormatter(row) {
        const status = Object.values(chargeStatus).find(x => x.string === row.ChargeStatus).value;
        return getRowChargeStatusCssClass(status);
    }

    _continue() {
        const event = new CustomEvent("continue");
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this.dispatchEvent(event);
    }

    _cancel() {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._modalComponent.closeModal();
        this.dispatchEvent(new CustomEvent("cancel"));
    }
}
customElements.define("additional-charges-dialog", AdditionalChargesDialog)