import "../AdditionalChargesDialog";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-table/dist/bootstrap-table";
import template from "./ReviewQueueBondApplicationReview.html";
import { initializeHtmlElement } from "./../HTMLElementExtensions";
import { createColumn, dollarFieldFormatter } from "./../BootstrapTableExtensions";
import { showAlert } from "./../EditDialogExtensions";
import "./../InmateInformation";
import "./../ActionButton";
import "../AlertMessage";
import "../Roster/SyncFailureDialog";
import { isAttributeTrue, openSyncFailureDialog } from "../JavaScriptFunctions";
import { FeatureFlag } from '../../types/Library/Common/Enumerations/FeatureFlag';

class ReviewQueueBondApplicationReview extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ["container"]);

        this._pageTools = new PageTools();
        this._inmateInformation = this.shadowRoot.getElementById("inmate-information");
        this._bondType = this.shadowRoot.getElementById("bond-type");
        this._bondCompany = this.shadowRoot.getElementById("bond-company");
        this._submittedBy = this.shadowRoot.getElementById("submitted-by");
        this._submittedDateTime = this.shadowRoot.getElementById("submitted-date-time");
        this._alert = this.shadowRoot.getElementById("modal-alert-view");
        this._table = this.shadowRoot.getElementById("table");
        this._returnButton = this.shadowRoot.getElementById("return-button");
        this._cancelButton = this.shadowRoot.getElementById("cancel-button");
        this._continueButton = this.shadowRoot.getElementById("continue-button");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._printPreviewAllButton = this.shadowRoot.getElementById("print-preview-all-button");
        this._syncFailureDialog = this.shadowRoot.getElementById("sync-failure-dialog");
        this._syncFailureDialog.isReviewPage = true;
        this._additionalChargesDialog = this.shadowRoot.getElementById("additional-charges-dialog");

        this._printPreviewOnClick = this._printPreviewOnClick.bind(this);
        this._printPreviewAllClick = this._printPreviewAllClick.bind(this);
        this._returnButtonOnClick = this._returnButtonOnClick.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
        this._continueButtonOnClick = this._continueButtonOnClick.bind(this);
        this._retryContinueReview = this._retryContinueReview.bind(this);
        this._continueReviewButtonOnClick = this._continueReviewButtonOnClick.bind(this);
        this._requestAdditionalOffenses = this._requestAdditionalOffenses.bind(this);
        this._continueOnSyncFailure = this._continueOnSyncFailure.bind(this);
        this._dialogCancelButtonOnClick = this._dialogCancelButtonOnClick.bind(this);
        this._model = null;
        this._bondApplicationId = null;
    }

    get _captureFeesAtEndOfReviewProcessFeatureFlag() {
        return isAttributeTrue(this, "capture-fees-at-end-of-review-process");
    }

    get _showCourtName() {
        return isAttributeTrue(this, "court-name-shows-for-bond-application-feature-flag");
    }

    set model(value) {
        this._model = value;
        this._updateHtml(value);

        const combinedModel = value.BondDetails.map(bondDetails => ({
            BondApplicationDetailID: bondDetails.BondApplicationDetailID,
            BondDetails: bondDetails
        }));

        this._loadTableData(combinedModel);
    }

    connectedCallback() {
        this._cancelButton.toggleAttribute("hidden", !isAttributeTrue(this, "hasCancelPermission"));
        this._returnButton.addEventListener("click", this._returnButtonOnClick);
        this._cancelButton.addEventListener("click", this._cancelButtonOnClick);
        this._continueButton.addEventListener("click", this._continueButtonOnClick);
        this._continueButton.textContent = this._captureFeesAtEndOfReviewProcessFeatureFlag ? "Continue" : "Finish Review";
        this._printPreviewAllButton.addEventListener("click", this._printPreviewAllClick);
        this._syncFailureDialog.addEventListener("retry", this._retryContinueReview);
        this._syncFailureDialog.addEventListener("continue", this._continueOnSyncFailure);
        this._additionalChargesDialog.addEventListener("continue", this._continueReviewButtonOnClick);
        this._additionalChargesDialog.addEventListener("cancel", this._dialogCancelButtonOnClick);
        this._inmateInformation.userIsCounty = isAttributeTrue(this, "user-is-county");
    }

    disconnectedCallback() {
        this._returnButton.removeEventListener("click", this._returnButtonOnClick);
        this._cancelButton.removeEventListener("click", this._cancelButtonOnClick);
        this._continueButton.removeEventListener("click", this._continueButtonOnClick);
        this._printPreviewAllButton.removeEventListener("click", this._printPreviewAllClick);
        this._syncFailureDialog.removeEventListener("retry", this._retryContinueReview);
        this._syncFailureDialog.removeEventListener("continue", this._continueOnSyncFailure);
        this._additionalChargesDialog.removeEventListener("continue", this._continueReviewButtonOnClick);
        this._additionalChargesDialog.removeEventListener("cancel", this._dialogCancelButtonOnClick);
    }

    showErrorMessage(errorMessage) {
        this._alertMessage.showErrorMessage(`${errorMessage} Please try again. If the problem persists <contact-link>contact eBONDS™ Support</contact-link>`, true);
    }

    _updateHtml(model) {
        this._bondApplicationId = model.BondApp.BondApplicationID;
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.BondApp.BondApplicationID);
        this._bondType.value = model.BondApp.BondTypeDisplayName;
        this._bondCompany.value = model.BondApp.BondCompanyName;
        this._submittedBy.value = model.BondApp.SubmittedBy;
        this._submittedDateTime.value = window.DateFormatter.utcToLocal(model.BondApp.SubmittedDateTime);
        this._additionalChargesDialog.showCourtName = this._showCourtName;
    }

    _loadTableData(model) {
        const table = $(this._table);
        const columns = [];
        columns.push(createColumn("Bond Number", "BondDetails.BondApplicationDetailID"));
        if (settings.featureFlags[FeatureFlag.CitationNumberShowsForBondApplication]) {
            columns.push(createColumn("Citation Number", "BondDetails.CitationNumber"))
        }
        if (settings.featureFlags[FeatureFlag.AgencyCaseNumberShowsForBondApplication]) {
            columns.push(createColumn("Agency Case Number", "BondDetails.AgencyCaseNumber"))
        }
        columns.push(createColumn("Degree", "BondDetails.OffenseDegree"));
        columns.push(createColumn("Offense", "BondDetails.OffenseDesc"));
        columns.push(createColumn("Bond Amount", "BondDetails.BondAmt", undefined, dollarFieldFormatter));
        if (model.some(item => parseFloat(item.BondDetails.CountyBondFee) >= 0 || item.BondDetails.CountyBondFee)) {
            columns.push(createColumn("Bond Fee", "BondDetails.CountyBondFee", undefined, dollarFieldFormatter));
        }
        if (model.some(item => item.BondDetails.POANumber)) {
            columns.push(createColumn("POA Number", "BondDetails.POANumber"));
        }
        columns.push(createColumn("Unofficial Bond Document","BondDetails.BondApplicationDetailID", false, this._printPreviewButtonFormatter));

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered",
            uniqueId: "BondApplicationDetailID"
        });
        this._printPreviewButtonSetup();
    }

    _printPreviewButtonFormatter(value, row, index) {
        return `<div><action-button class="btn btn-primary" detailid="${value}" trigger>Print Preview</action-button></div>`;
    }

    _printPreviewButtonSetup() {
        const printPreviewButtons = this.shadowRoot.querySelectorAll("action-button[detailid]");
        Array.from(printPreviewButtons).forEach(button => button.addEventListener("click", this._printPreviewOnClick));
    }

    _printPreviewAllClick() {
        const bondDetailIds = this._model.BondDetails.map(x => x.BondApplicationDetailID);
        this._pageTools.toggleTriggers(this.shadowRoot, true);

        const data = {
            ids: bondDetailIds
        };
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "/ReviewQueue/PrintAllBonds/", data, this._printPreviewCallback.bind(this));
    }

    _printPreviewOnClick(event) {
        const detailId = event.target.getAttribute("detailid");
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET", `/ReviewQueue/PrintBond/${detailId}`, null, this._printPreviewCallback.bind(this));
    }

    _printPreviewCallback(response, status) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        if (!status) {
            showAlert(this._alert, response);
            return;
        }
        const byteArrayParsed = this._pageTools.tryParseJson(response);
        const linkToPreview = this._createPdfAnchor(this._pageTools.b64toBlob(byteArrayParsed, "application/pdf"));
        linkToPreview.click();
    }

    _createPdfAnchor(file) {
        const elem = document.createElement("a");
        elem.setAttribute("href", URL.createObjectURL(file));
        elem.setAttribute("target", "_blank");
        return elem;
    }

    _retryContinueReview(event) {
        event.detail.buttonElement.click();
    }

    _performInmateRefresh(event) {
        const currentTarget = event.currentTarget;
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/Roster/RefreshInmate/BondApplicationId",
            { bondApplicationId: this._bondApplicationId },
            this._completeReviewSyncCallback.bind(this, currentTarget)
        );
    }

    _continueOnSyncFailure() {
        this._syncFailureDialog.closeDialog();
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._requestAdditionalOffenses();
    }

    _requestAdditionalOffenses() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "GET",
            `/Application/GetAdditionalTotalBondOffenses/${this._bondApplicationId}`,
            null,
            this._completeAdditionalOffensesCallback.bind(this)
        );
    }

    _completeReviewSyncCallback(buttonElement, response, isSuccess) {
        if (!isSuccess) {
            this._alertMessage.clearMessage();
            this._openSyncFailureDialog(buttonElement, response);
            return;
        }
        this._requestAdditionalOffenses();
    }

    _completeAdditionalOffensesCallback(response, isSuccess) {
        if (!isSuccess) {
            this._alertMessage.showErrorMessage("Please try again. If the problem persists <contact-link>contact eBONDS™ Support</contact-link>", true);
            return;
        }

        const offenses = this._pageTools.tryParseJson(response);
        if (offenses && offenses.length > 0) {
            const data = {
                InmateFullNameRev: this._model.Inmate.InmateFullNameRev,
                Offenses: offenses
            }
            this._additionalChargesDialog.openDialog(data);
            return;
        }

        this._continueReviewButtonOnClick();
    }

    _openSyncFailureDialog(buttonElement, response) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const responseObj = this._pageTools.tryParseJson(response);
        openSyncFailureDialog(responseObj,
            buttonElement,
            this._syncFailureDialog,
            isAttributeTrue(this, "data-skip-auto-sync-permission"),
            isAttributeTrue(this, "data-continue-on-sync-failure"),
            "There was a problem opening the sync failure dialog in the review queue.");
    }

    _returnButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl("/ReviewQueue");
    }

    _continueReviewButtonOnClick() {
        if (this._captureFeesAtEndOfReviewProcessFeatureFlag) {
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest(
                "GET",
                `/ReviewQueue/ShouldCaptureFees/${this._bondApplicationId}`,
                null,
                this._continueReviewButtonOnClickShouldCaptureFeesCallback.bind(this)
            );
        }
        else {
            this._sendReviewApplicationRequest();
        }
    }

    _sendReviewApplicationRequest() {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            "POST",
            "/ReviewQueue/ReviewApplication",
            {
                bondApplicationId: this._bondApplicationId
            },
            this._sendReviewApplicationRequestCallback.bind(this)
        );
    }

    _continueReviewButtonOnClickShouldCaptureFeesCallback(response, isSuccess) {
        if (!isSuccess) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            this._alertMessage.showErrorMessage(response, true);
            return;
        }
        if (JSON.parse(response)) {
            this._pageTools.redirectToUrl(`/ReviewQueue/CaptureFees/${this._bondApplicationId}`);
        } else {
            this._sendReviewApplicationRequest();
        }
    }

    _sendReviewApplicationRequestCallback(response, isSuccess) {
        if (!isSuccess) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            this._alertMessage.showErrorMessage(response, true);
            return;
        }

        this._pageTools.redirectToUrl(`/ReviewQueue`);
    }

    _continueButtonOnClick(event) {
        if (this._model.BondDetails.some(x => x.IsTotalBond)) {
            this._continueButton.setAttribute("alternatebuttontext", "Loading Inmate");
            this._pageTools.toggleTriggers(this.shadowRoot, true);
            this._performInmateRefresh(event);
            return;
        }

        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._continueReviewButtonOnClick();
    }

    _cancelButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Cancel/FinishCancel/${this._bondApplicationId}`);
    }

    _dialogCancelButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
    }
}

customElements.define("review-queue-bond-application-review", ReviewQueueBondApplicationReview);
