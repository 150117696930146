import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-table/dist/bootstrap-table";
import bootstrap from "../../scss/bootstrap-custom.scss";
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";
import { ActionButton } from "../ActionButton";
import { AlertMessage } from "../AlertMessage";
import { showAlert, clearAlert } from '../EditDialogExtensions';
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import template from "./BookOutQueueInmateInformationStep.html";

export class BookOutQueueInmateInformationStep extends HTMLElement {
    _pageTools: IPageTools;
    _returnButton: ActionButton;
    _confirmButton: ActionButton;
    _emailAddressInput: HTMLInputElement;
    _alertElement: AlertMessage;
    _emailParentContainer: HTMLElement;
    _emailContainer: HTMLElement;
    _bondApplicationId: number | undefined;
    _emailAddress: string | undefined;
    _model: string | undefined;

    constructor() {
        super();
        this._pageTools = new PageTools();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['container']);
        this._returnButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById('return-button'))
        this._confirmButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById('confirm-button'))
        this._emailAddressInput = <HTMLInputElement>nullThrow(this.shadowRoot?.getElementById('Email'));
        this._alertElement = <AlertMessage>nullThrow(this.shadowRoot?.getElementById("alert-message"));
        this._emailParentContainer = nullThrow(this.shadowRoot?.getElementById("email-parent-container"));
        this._emailContainer = nullThrow(this.shadowRoot?.getElementById("email-container"));

        this._returnButton.addEventListener('click', () => this._returnButtonOnClick());
        this._confirmButton.addEventListener('click', () => this._confirmButtonOnClick());
        this._initialState = this._initialState.bind(this);
        this._confirmEmailCallback = this._confirmEmailCallback.bind(this);
    }

    set model(value: { BondApp: { BondApplicationID: number }, Inmate: { EmailAddress: string } }) {
        this._bondApplicationId = value.BondApp.BondApplicationID;
        this._emailAddress = value.Inmate.EmailAddress;
        if (this._emailAddress !== undefined)
            this._emailAddressInput.value = this._emailAddress;
        if (settings.featureFlags[FeatureFlag.CollectInmateContactEmailAddress] &&
            settings.featureFlags[FeatureFlag.CollectInmateContactEmailAddressRequired]) {
            this._validateEmail();
            this._emailAddressInput.addEventListener('keyup', () => this._validateEmail());
        }
    }

    connectedCallback() {
        this._initialState();
    }
    
    enableStep() {
        this.toggleAttribute('hidden', false);
        this._initialState();
    }

    disableStep() {
        this.toggleAttribute('hidden', true);
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
    }

    _initialState() {
        if (settings.featureFlags[FeatureFlag.CollectInmateContactEmailAddress] &&
            settings.featureFlags[FeatureFlag.CollectInmateContactEmailAddressRequired]) {
            this._emailContainer.classList.add("required");
        }
        if (!settings.featureFlags[FeatureFlag.CollectInmateContactEmailAddress]) {
            this._emailParentContainer.toggleAttribute('hidden', true);
        }
    }
    _returnButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        this._pageTools.redirectToUrl("/BookOutQueue");
    }

    _validateEmail() {
        if ((this._emailAddressInput.value.trim() === "")) {
            this._confirmButton.setAttribute("disabled", "true");
        } else {
            this._confirmButton.removeAttribute("disabled");
        }
    }

    _confirmButtonOnClick() {
        if (!this._emailAddressInput.checkValidity()) {
            this._emailAddressInput.reportValidity();
            return;
        }
        const formData = this._generateFormData();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST",
            `/BookoutQueue/UpdateInmateContactInformation/`,
            formData,
            this._confirmEmailCallback
        );
    }

    _generateFormData() {
        const bondApplicationId = nullThrow(this._bondApplicationId);
        const formData = new FormData();
        formData.append("BondApplicationId", bondApplicationId.toString());
        formData.append("EmailAddress", this._emailAddressInput.value.trim());
        return formData;
    }

    _confirmEmailCallback(response: string, isSuccess: boolean) {
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
        if (!isSuccess) {
            showAlert(this._alertElement, response);
            return;
        }
        clearAlert(this._alertElement);
        const event = new CustomEvent("continue");
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        this.dispatchEvent(event);
    }
}
customElements.define("book-out-queue-inmate-information-step", BookOutQueueInmateInformationStep);