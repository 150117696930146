export function debounce(fn, delay) {
    let timeout;
    return function (abort) {
        clearTimeout(timeout);
        if (abort === true) {
            return;
        }
        const functionCall = fn.bind(this, arguments);
        timeout = setTimeout(functionCall, delay);
    };
}

export function openSyncFailureDialog(responseObj, buttonElement, syncFailureDialogElement, hasSkipPermission, continueOnSyncFailure, consoleErrorMessage) {
    if (responseObj) {
        const dialogInitializationObj = {
            hasSkipPermission: hasSkipPermission,
            continueOnSyncFailure: continueOnSyncFailure,
            inmateFN: responseObj.Inmate.InmateFN,
            inmateMI: responseObj.Inmate.InmateMI,
            inmateLN: responseObj.Inmate.InmateLN,
            bookingNumber: responseObj.Inmate.BookNumber,
            jailId: null, // This jailId property is only used for continue button when sync fails on the roster page
            buttonElementToRetry: buttonElement,
            lastSyncedTime: responseObj.LastSyncedTime,
            isOdysseyFailure: responseObj.IsOdysseyFailure,
            countyITPhoneNumber: responseObj.CountyItPhoneNumber
        }
        syncFailureDialogElement.openDialog(dialogInitializationObj);
    } else {
        console.error(consoleErrorMessage);
    }
}

export function isAttributeTrue(element, attributeName) {
    return element.hasAttribute(attributeName) &&
        element.getAttribute(attributeName).toLowerCase() === "true";
}

export function parseStringToNumberOrNull(stringValue) {
    return stringValue === undefined ||
        stringValue === null ||
        stringValue.trim() === "" || // checks value for both empty or whitespace
        isNaN(stringValue) ? null : Number(stringValue);
}

export function sortSelectElementOptions(selectElement) {
    const options = Array.from(selectElement.options);
    const selectOneOption = options.find(option => option.value === "");
    const otherOptions = options.filter(option => option.value !== "");

    otherOptions.sort((a, b) => a.text.localeCompare(b.text));

    if (selectOneOption)
        selectElement.add(selectOneOption);

    otherOptions.forEach(option => selectElement.add(option));
}

export function printPageBuilder(table) {
    let date = new Date;
    let companyAndTenant = settings.bondCompany === settings.tenant ? settings.tenant : settings.bondCompany + ' - ' + settings.tenant;

    return `
    <html>
        <head>
            <style type="text/css" media="print">
            @page {
              size: auto;
              margin: 25px 0 25px 0;
            }
            </style>
            <style type="text/css" media="all">
            table {
              border-collapse: collapse;
              font-size: 12px;
            }
            table, th, td {
              border: 1px solid grey;
            }
            th, td {
              text-align: center;
              vertical-align: middle;
            }
            p {
              font-weight: bold;
              margin-left:20px;
            }
            table {
              width:94%;
              margin-left:3%;
              margin-right:3%;
            }
            div.bs-table-print {
              text-align:center;
            }
            </style>
        </head>
        <title>Print Table</title>
        <body>
        <p>Printed on: ${date} - ${companyAndTenant}<p>
            <div class="bs-table-print">${table}</div>
        </body>
    </html>`
}