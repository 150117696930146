// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum FeatureFlag {
  UseFingerPrintScanner = 0,
  UseDemoFeatures = 1,
  BookoutCompleteShowsAllCharges = 2,
  PowersOfAttorneyReport = 3,
  PoaBanner = 4,
  PRBondStrictValidation = 5,
  ContinueOnSyncFailure = 6,
  CourtInformationHasAgencyCaseNumber = 7,
  RestrictCardsTemp = 8,
  BondCompaniesHaveFullRosterAccess = 9,
  AcceptsAllPaidPayments = 10,
  CountyUserHasAllPaidNavMenuItem = 11,
  HasMugshot = 12,
  HasPerOffenseFee = 13,
  BondTypeSurchargeScheduleTemp = 14,
  CourtInformationRequiredForCountyUsers = 15,
  CourtInformationOptionalForCountyUsers = 16,
  CourtInformationRequiredForBondUsers = 17,
  CourtInformationOptionalForBondUsers = 18,
  OffensesHaveSpecialConditions = 19,
  PrBondHasIssuingAuthority = 20,
  HaysConfirmNoHoldsTemp = 21,
  BondDocumentTemplatesAreAssignablePerOffense = 22,
  BondCompanyMugshotVerify = 23,
  SendEmailToBondCompanyOnApplicationSubmitted = 24,
  SendEmailToBondCompanyOnApplicationReviewed = 25,
  AllPaidDisablePartialPayments = 26,
  PreventBondCompaniesFromReceivingOrViewingBondDocuments = 27,
  CourtInformationCauseNumberRequired = 28,
  CourtInformationWarrantNumberRequired = 29,
  CaptureFeesAtEndOfReviewProcess = 30,
  AttorneyBarNumberExpirationRequired = 31,
  HasBookingSheet = 32,
  ReviewAuthorizationRequiredTemp = 33,
  FullApiInmateAccess = 34,
  RefreshOnCadence = 35,
  TenantWidePoaReportTemp = 36,
  EnforceBondApplicationLimitsTemp = 37,
  BondDocumentCertificationTemp = 38,
  ShortcutBookoutQueueProcessTemp = 39,
  RequireAuthorizationSignatureAtBookout = 40,
  BondCompaniesOnlyGetUnofficialBondDocuments = 41,
  ExpandUserRolesTemp = 42,
  ConfigurableTimeoutTemp = 43,
  SingleSessionSignOnTemp = 44,
  RestrictIssuingAuthority = 45,
  CollateralLimitEnforcement = 46,
  OffensesHaveVerboseSpecialConditions = 47,
  TotalBonds = 48,
  UserLevelPoaLicense = 49,
  HideMugshotsForJailAccounts = 50,
  HideMugshotsForCompanyAccounts = 51,
  HideMugshotsForPaymentPortal = 52,
  ShowPaymentMethod = 53,
  StoreCompaniesAssignedToUserInDatabaseTemp = 54,
  AllowPaymentPortalInmateReleaseNotification = 55,
  PaymentPortalPlcCheckTemp = 56,
  NotifyTenantOfDataProcessingIssuesTemp = 57,
  PaymentPortalRequiresPayorIdentifyingInformation = 58,
  ShowBookingReportOfficialUseOnlyText = 59,
  DataEntryErrorLogicTemp = 60,
  UpdateFeeCalculationTemp = 61,
  ShowBookingReportSpecialConditions = 62,
  BondDocumentsBookedOutReportShowsCourtNameTemp = 63,
  RequireAdditionalAuthorizationSignatureAtBookout = 64,
  CourtNameShowsForBondApplication = 65,
  CourtInformationReadOnly = 66,
  EnforceOwnerBondCompanyCollateralLimit = 67,
  HideAdditionalInmateInformationInputs = 68,
  OptionalParcelNumber = 69,
  AttorneyCompaniesTemp = 70,
  ExtendedAllPaidValidationTemp = 71,
  BondPaymentCaptureCourtTemp = 72,
  CollectInmateContactInformation = 73,
  CollectInmateContactEmailAddress = 74,
  CollectInmateContactEmailAddressRequired = 75,
  SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp = 76,
  AllowAmendingBondDocuments = 77,
  CancelAmendedBondDocumentsTemp = 78,
  StoreBondDocumentImagesTemp = 79,
  AllowEditingBondDocuments = 80,
  CitationNumberShowsForBondApplication = 81,
  CitationNumberRequired = 82,
  CitationNumberOptional = 83,
  CitationNumberReadOnly = 84,
  AgencyCaseNumberShowsForBondApplication = 85
}