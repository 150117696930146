import { dollarFieldFormatter } from '../BootstrapTableExtensions.js';
import poaStatus from '../Enumerations/PoaStatus.js';
import { initializeHtmlElement } from '../HTMLElementExtensions';

class PowersOfAttorneyGrid extends HTMLElement {

    constructor() {
        super();
        initializeHtmlElement(
            this,
            '<bootstrap-report-grid id="powers-of-attorney"></bootstrap-report-grid>',
            [],
            []
        );
        this._bootstrapReportGrid = this.shadowRoot.querySelector('bootstrap-report-grid');
    }

    isCountyUser() {
        const attr = 'data-is-county-user';
        return this.hasAttribute(attr) && this.getAttribute(attr).toLowerCase() === 'true';
    }

    connectedCallback() {
        this._bootstrapReportGrid.setReportDate(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00'), moment().format('YYYY-MM-DDT23:59'));
        const tableConfig = this._createTableConfig();
        this._bootstrapReportGrid.initBootstrapReportGrid(tableConfig);
    }

    _compileColumns() {
        const columns = [
            {
                title: 'Power',
                field: 'PoaNumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Power Limit',
                field: 'PoaLimit',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter

            },
            {
                title: 'Power Status',
                field: 'PoaCurrentStatus',
                type: 'Text',
                escape: true,
                formatter: this.poaStatusFormatter
            },
            {
                title: 'Bond Number',
                field: 'BondApplicationDetailId',
                type: 'Number',
                escape: true
            },
            {
                title: 'Bond Type',
                field: 'BondTypeDisplayName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Amount',
                field: 'bondAmount',
                type: 'Number',
                escape: true,
                formatter: dollarFieldFormatter
            },
            {
                title: 'Inmate',
                field: 'InmateFullName',
                type: 'Text',
                escape: true
            },
            {
                title: 'Bond Application Status',
                field: 'BondApplicationStatus' ,
                type: 'Text',
                escape: true,
                formatter: this._bootstrapReportGrid.applicationStatusFormatter
            },
            {
                title: 'Cancel Reason',
                field: 'CancelReason',
                type: 'Text',
                escape: true
            },
            {
                title: 'Canceled By',
                field: 'CanceledBy',
                type: 'Text',
                escape: true
            },
            {
                title: 'Updated Time',
                field: 'UpdatedDateTime',
                type: 'Date',
                escape: true,
                formatter: this._bootstrapReportGrid.dateFormatter.bind(this)
            }
        ];

        if (this.isCountyUser()) {
            columns.unshift({
                title: 'Bond Company',
                field: 'BondCompany',
                type: 'Text',
                escape: true
            });
        }

        return columns;
    }

    _createTableConfig() {
        return {
            uniqueId: 'PowersOfAttorneyGrid',
            tableColumns: this._compileColumns(),
            pageSize: 20,
            detailView: false,
            mainTableHeight: 600,
            print: true,
            export: true,
            exportFilename: 'Powers-of-Attorney',
            reportUrl: '/Reports/PowersOfAttorney_Read',
            restoreFilters: true,
            tableName: "PowersOfAttorney",
            tableClasses: 'table-striped',
            paginationDetails: true,
            toolbar: true,
            columnFilters: true,
            sortable: true,
            tableAlertMessage: true
        };
    }

    poaStatusFormatter(value) {
        if (isNaN(value))
            return poaStatus[`${value}`] !== undefined ?
                poaStatus[`${value}`].string : value;
        else
            return value;
    }
}
customElements.define('powers-of-attorney-grid', PowersOfAttorneyGrid);