import { JmsBondTypeViewModel } from "../types/Website/Bonds/Models/JmsBondTypeViewModel";
import { BondChargeViewModel } from "../types/Website/Bonds/Models/BondChargeViewModel";
import { PaymentPortalInmateCharge } from "../types/Website/Bonds/Models/PaymentPortalInmateCharge";
import { ChargeStatus } from "../types/Website/Bonds/Enumerations/ChargeStatus";
import { AggregateBy } from "~/types/Entity/Bonds/ReferenceData/AggregateBy";

export function nullThrow<T>(value: T | null | undefined): T {
    if (value === null) {
        throw Error("Value was null");
    } else if (value === undefined) {
        throw Error("Value was undefined");
    } else {
        return value;
    }
}

// https://stackoverflow.com/a/34890276/7412948
// groupBy(['one', 'two', 'three'], 'length')
// => {"3": ["one", "two"], "5": ["three"]}
function groupBy<T>(items: T[], property: keyof T): T[][] {
    return items.reduce(function (accumulator: T[][], item: T) {
        const key: any = item[property];
        if (accumulator[key] === undefined) {
            accumulator[key] = [];
        }
        accumulator[key].push(item);
        return accumulator;
    }, []);
};

export function getTotalBondGrouping<T extends BondChargeViewModel | PaymentPortalInmateCharge>(charges: T[], jmsBondTypeConfigs: JmsBondTypeViewModel[], bondAmountProperty: keyof T) {
    // Remove charges that don't have a warrant number that is valid for grouping
    let aggregateBondGroups: T[][] = [];
    let chargesWithValidWarrant = charges.filter(x => isValidGroupingField(x.WarrantNumber));

    // Aggregate Bonds are grouped by their JMS bond type's AggregateBy field
    if (jmsBondTypeConfigs.some(x => x.CanBeAggregateBond)) {
        aggregateBondGroups = getAggregateBondGroupsFromCharges();
        chargesWithValidWarrant = removeChargesThatBelongToGroup(aggregateBondGroups);
    }

    const validTotalBondCharges = chargesWithValidWarrant.filter(charge => isConfiguredAsTotalBondParentOrTotalBondChild(charge));
    let groups = Object.values(groupBy(validTotalBondCharges, "WarrantNumber"));
    // Remove any groups of valid simple bonds so they are displayed individually
    groups = groups.filter(group => !isSimpleBond(jmsBondTypeConfigs, group, bondAmountProperty));

    return groups.concat(aggregateBondGroups);

    function isConfiguredAsTotalBondParentOrTotalBondChild(charge: T) {
        const config = getJmsBondTypeConfig(jmsBondTypeConfigs, charge);
        return config.CanBeTotalBondParent || config.CanBeTotalBondChild;
    }

    function getAggregateBondGroupsFromCharges() {
        let chargeGroups: T[][] = [];
        // Behind the scenes, enum objects have two properties for every value (a name->number conversion and number->name conversion).
        // Ergo, dividing by two gets the total number of values.
        // There aren't any built-in ways of iterating over the values of enums in TypeScript,
        // so hacky methods like this are the only option.
        // https://www.basedash.com/blog/how-to-iterate-enums-in-typescript
        for (let i = 0; i < Object.keys(AggregateBy).length / 2; i++) {
            if (i == AggregateBy.None) {
                continue;
            }
            chargeGroups = chargeGroups.concat(getAggregateBondGroupsForAggregateType(i));
        }
        return chargeGroups;
    }

    function getAggregateBondGroupsForAggregateType(aggregateBy: AggregateBy): T[][] {
        const citationCharges = charges.filter(charge => hasAggregateTypeWithField(charge, aggregateBy, jmsBondTypeConfigs)
            && isValidGroupingField(getAggregateByField(charge, aggregateBy)));
        return Object.values(groupBy(citationCharges, getAggregateByFieldName(aggregateBy))).filter(x => x.length > 1);
    }

    function getAggregateByFieldName(aggregateBy: AggregateBy): keyof T {
        switch (aggregateBy) {
            case AggregateBy.CourtName:
                return "CourtName";
            case AggregateBy.CitationNumber:
                return "CitationNumber";
            case AggregateBy.AgencyCaseNumber:
                return "AgencyCaseNumber";
            default:
                throw Error("Invalid AggregateBy field");
        }
    }

    function removeChargesThatBelongToGroup<T extends BondChargeViewModel | PaymentPortalInmateCharge>(chargeGroups: T[][]) {
        const chargeIdsToRemove: (string | null)[] = [];
        chargeGroups.forEach(chargeGroup => {
            chargeGroup.forEach(charge => chargeIdsToRemove.push(charge.JmsChargeId));
        });
        return chargesWithValidWarrant.filter(charge => !chargeIdsToRemove.includes(charge.JmsChargeId));
    }
}

export function totalBondGroupErrors<T extends BondChargeViewModel | PaymentPortalInmateCharge>(group: T[], tenantId: number, jmsBondTypeConfigs: JmsBondTypeViewModel[], bondAmountProperty: keyof T, tenantPhoneNumber: string | null) {
    let phoneNumber = tenantPhoneNumber ? ` at ${tenantPhoneNumber.replace(/(\d{3})\s(\d{3})\s(\d{4})/, '($1) $2-$3')}` : '';
    let groupTypeDesc = "Total Bond (offenses that share a common Warrant Number)";
    if (isAggregateBond()) {
        const bondType = getJmsBondTypeConfig(jmsBondTypeConfigs, group[0]);
        groupTypeDesc = "Aggregate Bond (offenses that share a common " + getAggregateByDisplayName(bondType.AggregateBy) + ")";
    }

    const notAvailableError = `A selected ${groupTypeDesc} includes an offense with a Status that is not Available. Contact the jail${phoneNumber} for assistance.`;
    const invalidBondAmountError = `A selected ${groupTypeDesc} includes an offense with an unspecified bond amount. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.`;
    const invalidConfigError = `A selected ${groupTypeDesc} includes bond types that are not configured in such a way to allow for a total bond, an aggregate bond, or a simple bond. If a Bond Type is not correct, click the Back button below, change the Bond Type in your jail management system, and click the Create Application button for this inmate.`;
    const exactlyOneNonZeroError = `A selected Total Bond (offenses that share a common Warrant Number) does not include exactly one offense with a non-zero Bond Amount. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.`;
    const simpleBondWithZeroError = `A selected Total Bond (offenses that share a common Warrant Number) includes offenses with a zero Bond Amount and cannot be created as a group of simple bonds. If a Bond Amount is incorrect, click the Back button below, change the Bond Amount in your jail management system, and click the Create Application button for this inmate.`;
    const fultonMixingOffensesError = `A selected Total Bond (offenses that share a common Warrant Number) includes both felony and misdemeanor offenses. Felony and misdemeanor offenses cannot be combined on a Total Bond. Check the Degree of these offenses. If a Degree is incorrect, click the Back button below, change the Degree in your jail management system, and click the Create Application button for this inmate.`;
    const invalidAggregateBondError = `A selected group of charges are not properly configured aggregate bonds. If a Bond Type is not correct, click the Back button below, change the Bond Type in your jail management system, and click the Create Application button for this inmate.`;

    if (!allChargesAreAvailable())
        return notAvailableError;

    if (!allChargesHaveValidBondAmount())
        return invalidBondAmountError;

    if (!bondTypesAreConfiguredToAllowTotalBond() && !bondTypesAreConfiguredToAllowSimpleBondInternal() && !bondTypesAreConfiguredToAllowAggregateBond())
        return invalidConfigError;

    if (!isSimpleBondInternal() && !isTotalBond() && !isAggregateBond()) {
        return checkGroupingCategory();
    }

    // Customer specific logic below here
    if (isFulton() && !allFultonChargesAreSameTypeOfOffenseDegree())
        return fultonMixingOffensesError;

    return null;

    function checkGroupingCategory() {
        // We aren't a simple, total, or aggregate bond; now lets figure why its invalid
        if (bondTypesAreConfiguredToAllowAggregateBond() && !isAggregateBond())
            return invalidAggregateBondError;

        if (bondTypesAreConfiguredToAllowTotalBond() && !hasExactlyOneChargeWithBondAmount()) {
            return exactlyOneNonZeroError;
        }

        if (bondTypesAreConfiguredToAllowSimpleBondInternal() && !allChargesHaveANonZeroBondAmount()) {
            return simpleBondWithZeroError;
        }

        // A valid set of charges, but with the wrong configurations exists. Parent charge has child config, child charge has parent config.
        return invalidConfigError;
    }

    function allChargesAreAvailable() {
        // Payment portal doesn't have a charge status so its undefined, which we assume is available            
        return group.every(charge => !('ChargeStatus' in charge) || charge.ChargeStatus === ChargeStatus.Available);
    }

    function allChargesHaveValidBondAmount() {
        return group.every(charge => charge[bondAmountProperty] !== null);
    }

    function isFulton() {
        return tenantId === 4;
    }

    function allFultonChargesAreSameTypeOfOffenseDegree() {
        return group.every(charge => charge.OffenseDegree && ["F", "SF"].includes(charge.OffenseDegree)) ||
            group.every(charge => charge.OffenseDegree &&["M", "O"].includes(charge.OffenseDegree));
    }

    function isTotalBond() {
        // If allowed to be an aggregate bond, then we're not going to be a total bond. Ever. But we could be an invalid aggregate bond, so we only check the config instead of calling isAggregateBond
        const isPossibleAggregateBond = group.every(charge => getJmsBondTypeConfigInternal(charge).CanBeAggregateBond);
        const areTotalBondParentsValid = group.filter(charge => charge[bondAmountProperty] as number > 0 && getJmsBondTypeConfigInternal(charge).CanBeTotalBondParent).length === 1;
        const areTotalBondChildrenValid = group.filter(charge => charge[bondAmountProperty] === 0 && getJmsBondTypeConfigInternal(charge).CanBeTotalBondChild).length === group.length - 1;
        return !isPossibleAggregateBond && areTotalBondParentsValid && areTotalBondChildrenValid;
    }

    function isAggregateBond() {
        for (let i = 0; i < Object.keys(AggregateBy).length / 2; i++) {
            if (i == AggregateBy.None) {
                continue;
            }
            if (isAggregateBondByField(i)) {
                return true;
            }
        }
        return false;
    }

    function isAggregateBondByField(aggregateBy: AggregateBy) {
        return group.every(charge =>
            charge[bondAmountProperty] as number >= 0 &&
            hasAggregateTypeWithField(charge, aggregateBy, jmsBondTypeConfigs) &&
            isValidGroupingField(getAggregateByField(charge, aggregateBy))
        );
    }

    function getAggregateByDisplayName(aggregateBy: AggregateBy): string {
        switch (aggregateBy) {
            case AggregateBy.CourtName:
                return "Court Name";
            case AggregateBy.CitationNumber:
                return "Citation Number";
            case AggregateBy.AgencyCaseNumber:
                return "Agency Case Number";
            default:
                throw Error("Invalid AggregateBy field");
        }
    }

    function bondTypesAreConfiguredToAllowTotalBond() {
        return group.filter(charge => getJmsBondTypeConfigInternal(charge).CanBeTotalBondParent).length >= 1 &&
            group.filter(charge => getJmsBondTypeConfigInternal(charge).CanBeTotalBondChild).length >= 1;
    }

    function bondTypesAreConfiguredToAllowAggregateBond() {
        return group.every(charge => getJmsBondTypeConfigInternal(charge).CanBeAggregateBond);
    }

    function hasExactlyOneChargeWithBondAmount() {
        return group.filter(charge => charge[bondAmountProperty] as number > 0).length === 1;
    }

    function allChargesHaveANonZeroBondAmount() {
        return group.every(charge => charge[bondAmountProperty] as number > 0);
    }

    function isSimpleBondInternal() {
        return isSimpleBond(jmsBondTypeConfigs, group, bondAmountProperty);
    }

    function bondTypesAreConfiguredToAllowSimpleBondInternal() {
        return bondTypesAreConfiguredToAllowSimpleBond(jmsBondTypeConfigs, group);
    }

    function getJmsBondTypeConfigInternal<T extends BondChargeViewModel | PaymentPortalInmateCharge>(charge: T) {
        return getJmsBondTypeConfig(jmsBondTypeConfigs, charge);
    }
}

function isValidGroupingField(groupingField: string | null) {
    return !!groupingField?.trim();
}

function isSimpleBond<T extends BondChargeViewModel | PaymentPortalInmateCharge>(configs: JmsBondTypeViewModel[], group: T[], bondAmountProperty: keyof T) {
    const hasBondAmount = group.every(charge => charge[bondAmountProperty] as number >= 0);
    const isConfiguredForSimpleBonds = bondTypesAreConfiguredToAllowSimpleBond(configs, group);
    return hasBondAmount && isConfiguredForSimpleBonds;
}

function bondTypesAreConfiguredToAllowSimpleBond<T extends BondChargeViewModel | PaymentPortalInmateCharge>(configs: JmsBondTypeViewModel[], group: T[]) {
    return group.every(charge => {
        const config = getJmsBondTypeConfig(configs, charge);
        return config.CanBeSimpleBond && (group.length === 1 || config.CanShareGroupWithAnotherSimpleBond);
    });
}

function hasAggregateTypeWithField<T extends BondChargeViewModel | PaymentPortalInmateCharge>(charge: T, aggregateBy: AggregateBy, jmsBondTypeConfigs: JmsBondTypeViewModel[]) {
    const bondType = getJmsBondTypeConfig(jmsBondTypeConfigs, charge);
    return bondType.CanBeAggregateBond && bondType.AggregateBy == aggregateBy;
}

function getAggregateByField<T extends BondChargeViewModel | PaymentPortalInmateCharge>(charge: T, aggregateBy: AggregateBy): string | null {
    switch (aggregateBy) {
        case AggregateBy.CourtName:
            return charge.CourtName;
        case AggregateBy.CitationNumber:
            return charge.CitationNumber;
        case AggregateBy.AgencyCaseNumber:
            return charge.AgencyCaseNumber;
        default:
            throw Error("Invalid AggregateBy field");
    }
}

function getJmsBondTypeConfig<T extends BondChargeViewModel | PaymentPortalInmateCharge>(configs: JmsBondTypeViewModel[], charge: T): JmsBondTypeViewModel {
    // If nothing is configured, assume you can do everything
    if (configs.length === 0)
        return {
            Name: "Default",
            CanBeTotalBondParent: true,
            CanBeTotalBondChild: true,
            CanBeSimpleBond: true,
            CanShareGroupWithAnotherSimpleBond: false,
            CanBeAggregateBond: false,
            AggregateBy: AggregateBy.None
        };
    return configs.find((config: JmsBondTypeViewModel) => config.Name.toLowerCase() === charge.BondType?.toLowerCase()) || {
        Name: "Default",
        CanBeTotalBondParent: false,
        CanBeTotalBondChild: false,
        CanBeSimpleBond: true,
        CanShareGroupWithAnotherSimpleBond: true,
        CanBeAggregateBond: false,
        AggregateBy: AggregateBy.None
    };
}