import "../ActionButton";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import template from "./BondTypeManagementJmsBondTypeGrid.html";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import { ButtonRenderer } from "../Grid/ButtonRenderer";
import { BasicGrid } from "../Grid/BasicGrid";
import { CreateEnumColumn } from "../Grid/CustomDataColumns";
import { AggregateBy } from "../../types/Entity/Bonds/ReferenceData/AggregateBy";
import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";
import { basicAddDeleteButtonCellRendererSelector, getPinnedAddRowStyle } from "../Grid/BasicGridFunctions";
import type { CellRendererSelectorResult, GridApi, GridOptions, ICellRendererParams } from "ag-grid-enterprise";
import type { JmsBondTypeViewModel } from "../../types/Website/Bonds/Areas/SysAdmin/Models/JmsBondTypeViewModel";

export class BondTypeManagementJmsBondTypeGrid extends HTMLElement {
    public gridApi: GridApi<JmsBondTypeViewModel> | undefined;
    public _data: JmsBondTypeViewModel[] = [];

    public set data(value: JmsBondTypeViewModel[]) {
        this._data = value;
        this.gridApi?.setGridOption("rowData", this._data);
    }

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], []);
    }

    connectedCallback() {
        const grid = <BasicGrid>nullThrow(this.shadowRoot?.getElementById("grid"));
        const options: GridOptions<JmsBondTypeViewModel> = {
            pinnedTopRowData: [this.createEmptyJmsBondType()],
            suppressNoRowsOverlay: true,
            stopEditingWhenCellsLoseFocus: true,
            getRowStyle: getPinnedAddRowStyle<JmsBondTypeViewModel>,
            defaultColDef: {
                filter: "agTextColumnFilter",
                floatingFilter: true,
                editable: true,
                singleClickEdit: true,
                wrapHeaderText: true,
                autoHeaderHeight: true
            },
            columnDefs: [
                {
                    headerName: "JMS Bond Type",
                    field: "Name",
                    sort: 'asc'
                },
                { field: "CanBeTotalBondParent", cellDataType: "boolean", editable: settings.featureFlags[FeatureFlag.TotalBonds] },
                { field: "CanBeTotalBondChild", cellDataType: "boolean", editable: settings.featureFlags[FeatureFlag.TotalBonds] },
                { field: "CanBeSimpleBond", cellDataType: "boolean", editable: settings.featureFlags[FeatureFlag.TotalBonds] },
                {
                    field: "CanShareGroupWithAnotherSimpleBond",
                    cellDataType: "boolean",
                    width: 300,
                    editable: settings.featureFlags[FeatureFlag.TotalBonds]
                },
                { field: "CanBeAggregateBond", cellDataType: "boolean", editable: settings.featureFlags[FeatureFlag.TotalBonds] },
                CreateEnumColumn("AggregateBy", AggregateBy, (params) => params.data?.CanBeAggregateBond === true),
                {
                    flex: 1,
                    minWidth: 290,
                    filter: false,
                    floatingFilter: false,
                    cellRenderer: ButtonRenderer,
                    cellRendererSelector: (params: ICellRendererParams<JmsBondTypeViewModel>): CellRendererSelectorResult => {
                        const result = basicAddDeleteButtonCellRendererSelector(params, this.createEmptyJmsBondType, this.getDataReference.bind(this), "JMS Bond Type")
                        if(params.node.isRowPinned()) {
                            result.params.value.buttons.unshift({
                                onClick: this.importClicked.bind(this),
                                text: "Import JMS Bond Types",
                                color: "btn-success"
                            });
                        }
                        return result;
                    },
                    cellStyle: {'font-style': 'normal'}
                }
            ]
        };
        
        this.gridApi = grid.createGrid(options);
    }
    
    private createEmptyJmsBondType(): JmsBondTypeViewModel {
        return <JmsBondTypeViewModel>{
            JmsBondTypeId: null,
            Name: "New JMS Bond Type...",
            CanBeTotalBondParent: false,
            CanBeTotalBondChild: false,
            CanBeSimpleBond: false,
            CanShareGroupWithAnotherSimpleBond: false,
            CanBeAggregateBond: false,
            AggregateBy: AggregateBy.None
        }
    }

    private getDataReference(): JmsBondTypeViewModel[] {
        return this._data;
    }

    private importClicked(this: BondTypeManagementJmsBondTypeGrid) {
        const callback = (response: string, status: boolean) => {
            this.gridApi?.setGridOption("loading", false);
            if (!status) {
                this.dispatchAlert(response, true);
                return;
            }
            const data = JSON.parse(response) as string[]
            const newData = data.filter(x => this._data.find(bondType => bondType.Name === x) === undefined);
            const newBondTypes = newData.map(x => {
                const newBondType = this.createEmptyJmsBondType();
                newBondType.Name = x;
                return newBondType;
            })
            this._data.push(...newBondTypes);
            this.gridApi?.setGridOption("rowData", this._data);
        }

        this.dispatchAlert();
        this.gridApi?.setGridOption("loading", true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET", "BondTypes/GetOffenseBondTypes", null, callback)
    }

    private dispatchAlert(this: BondTypeManagementJmsBondTypeGrid, message: string = "", error: boolean = false) {
        const event = new CustomEvent("alert", { detail: { message: message, error: error }})
        this.dispatchEvent(event);
    }
}

customElements.define("bond-type-management-jms-bond-type-grid", BondTypeManagementJmsBondTypeGrid);